.search-item {
    display: flex;

    & &--thumbnail {
        width: 220px;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 12px;
        text-align: center;
        max-width: none;
        position: relative;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: $classicBorder;

        &:hover {
            .preview--placeholder,
            .video-preview--placeholder {
                visibility: visible;
                opacity: 0.7;
            }
        }

        @include centeredSmallThumbnail();

        .restrictedDownload:before,
        .restrictedContent:before {
            background-size: 100px;
        }
    }

    &-content {
        padding-left: 17px;
        box-sizing: border-box;
        &:not(.asset-preview-content) {
            box-sizing: border-box;
            padding: calc(#{$indentBasis} / 2) calc(#{$indentBasis} * 2) $indentBasis;
            width: calc(100% - 220px);
        }

        &--actions-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: $classicBorder;
            border-bottom: $classicBorder;
            padding: $indentBasis calc(#{$indentBasis} * 2);
        }
        &--actions--global,
        &--actions-container {
            .asset_actions_dropdown,
            .dropdown-title.button--action {
                margin-top: 0;
            }
            .asset_actions_dropdown {
                height: 24px;
                line-height: 24px;
                margin-right: -9px;
            }
        }
        &--actions--global {
            position: absolute;
            top: 8px;
            right: 10px;

            .asset_actions_dropdown {
                margin-right: 0;
            }
    
            .dropdown-title.button--action {
                margin-top: 0;
            }
    
            .asset-actions-popup {
                right: 40px;
                top: 0px;
            }
        }
        &--sap-id {
            font-family: $BrandMedium;
            margin-top: 2px;
            color: $greyDefault;
        }

        .asset__id-label {
            margin-right: 3px;
            font-size: 12px;
            overflow: visible!important;
        }

        &--name {
            font-size: 16px;
            line-height: 24px;
            font-family: $BrandRegular;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 4px 0;
            text-align: left;
            transition: color .2s;

            &.linkText.button--action {
                padding: 4px 0;
                font-size: 16px;
                line-height: 24px;
                font-family: $BrandMedium;
                text-transform: none;
            }

            b {
                font-family: $BrandMedium;
            }
        }

        &--title {
            display: flex;
            padding-right: 25px;
        }

        &--highlightedContent {
            height: 48px;
            font-size: 14px;
            line-height: 24px;
            font-family: $BrandRegular;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 8px;
            color: $greyDefault;

            b {
                font-family: $BrandMedium;
            }
        }
    }
}

.custom_asset--small {
    .search-item {
        .search-item--thumbnail {
            width: 160px;
        }

        .search-item-content {
            width: calc(100% - 160px);
        }

        .search-item-content--other-info {
            margin-top: calc(#{$indentBasis} * 2);
        }
    }
}

.search-item-content--other-info {
    .with-translations {
        position: relative;
        right: 5px;
        .lang-counter {
            position: absolute;
            top: 2px;
            right: 13px;
            color: $blue;
        }
        .search-item-content__lang-dropdown {
            padding-bottom: 0;
            height: 24px;
            .dropdown-title {
                font-size: 12px;
                color: $greyDefault;
                font-family: $BrandMedium;
                height: 100%;
                padding: 0 20px 0 5px;

                & > span {
                    margin-left: 0;
                    line-height: 18px;
                }
            }
        }
        .dropdown-body {
            &__content {
                &__items {
                    & > * {
                        padding: 0;
                    }

                    .button--action {
                        width: 100%;
                    }

                    .asset-lang-option {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        line-height: 19px;
                        padding: 5px 13px;
                        cursor: pointer;
                        transition: .3s;
                        &:hover {
                            background: $greyLight7;
                        }
                    }
                }

                &__close {
                    svg {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.custom_asset--preview {
    position: relative;
    display: block;
    text-align: left;
    padding: 0;
    border-radius: 12px;
    white-space: normal;
    box-shadow: $grayShadow1;
    border: $folderItemBorder;
    box-sizing: border-box;
    background: $white;
    color: $blue;

    &:hover {
        box-shadow: $grayShadow2;
        border-color: $blue;
    }

    .bulk-actions-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        @media #{$IE} {
            top: 0;
        }

        .base-checkbox__icon {
            margin-right: 0;
        }
    }
}

.dt-cell {
    .custom_asset--preview {
        &:hover {
            box-shadow: none;
        }
    }
}

.grid-view {
    .page {
        .search-results:not(.search-results--list) {
            .custom_asset--preview,
            .custom_folder--preview {
                height: 100%;

                .search-item {
                    display: block;

                    &--thumbnail {
                        display: block;
                        width: 100%;
                        position: relative;
                        margin-right: 0;
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        overflow: hidden;
                    }

                    &-content {
                        width: auto;
                        white-space: normal;
                        padding: 4px calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 2);

                        &--title {
                            padding-right: 0;
                        }

                        &--highlightedContent {
                            height: 48px;
                        }
                    }
                }

                .asset-actions-trigger {
                    &.button--action {
                        margin-right: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
