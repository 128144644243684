.add-subtitle-modal {
    .base-modal {
        height: 400px;
        &--body {
            height: calc(100% - 120px);
        }
    }

    .info-tooltip {
        position: absolute;
        top: -5px;
        left: 70px;
    }

    .replace-thumbnail-modal__text {
        & > div {
            &:first-child {
                .input-pair {
                    padding-top: 0;
                }
            }
        }
    }

    .file-preview {
        margin-top: 0.5*$indentBasis;
        margin-bottom: 0;
        border: none;
        padding: $indentBasis 0;
        display: flex;
        align-items: center;
        .files-for-upload__item__image {
            height: 32px;
            min-width: 24px;
            margin-right: 2*$indentBasis;
            margin-top: 0.5*$indentBasis;
        }
        .file-preview__name {
            font-family: $BrandRegular;
        }
        .file-preview__button {
            margin-left: $indentBasis;
        }
        .file-preview__size {
            font-size: 12px;
            line-height: 18px;
            color: $greyDarker;
            margin-top: 0.5 * $indentBasis;
        }
    }

    .subtitle-errors {
        padding-left: 40px;
        font-size: 12px;
        line-height: 18px;

        span {
            color: $red;
        }

        svg {
            width: 13px;
            height: 13px;
            margin-right: $indentBasis;
            path {
                fill: $red;
            }
        }

        .collapse-button {
            padding: $indentBasis 0 0;
            border-bottom: none;
        }

        .collapse-toggle__title {
            font-family: $BrandRegular !important;
        }

        .collapse-body {
            padding: 0;
        }

        .subtitle-error {
            display: flex;
            align-items: center;
        }

        .single-subtitle-error {
            padding-left: 0;
            padding-top: 2*$indentBasis;
            margin-left: -40px;
        }

        .sublitle-error-chevron {
            padding-top: 2px;
            line-height: 13px;
            svg {
                margin-left: $indentBasis;
                transition: transform .3s;
            }
        }

        .subtitle-errors-list {
            padding-top: 2*$indentBasis;
        }

        .collapse--opened {
            .sublitle-error-chevron {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .subtitle-error {
        font-size: 12px;
        line-height: 18px;
        color: $red;
    }

    .input-pair {
        .error {
            float: none;
        }
    }
}