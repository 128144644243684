%full {
    width: 100% !important;
    height: 100% !important;
}
.vjs-hide-fullscreen {
    .vjs-fullscreen-control {
        display: none;
    }
}
.page--video-embed {
    @extend %full;

    body {
        @extend %full;
        #app {
            @extend %full;

            & > div {@extend %full;}

            #page {
                @extend %full;

                .asset-preview-widget {
                    @extend %full;

                    & > div {@extend %full;}
                }

                .video-js {@extend %full;}
            }
        }
    }
}

.video-js {
    &.restrictedContent:before {
        z-index: 1;
    }

    .vjs-control-bar,
    .vjs-big-play-button {
        z-index: 2;
    }
    .vjs-big-play-button {
        z-index: 2;
    }
    .vjs-volume-control {
        .vjs-volume-bar {
            margin: 1.85em .45em;
        }
    }

    .vjs-duration {
        margin-right: auto;
    }

    .vjs-time-divider {
        padding-left: 0;
        padding-right: 0;
        min-width: 0;
    }

    &.vjs-vtt-thumbnails {
        display: block;
    }

    .vjs-vtt-thumbnail-display {
        position: absolute;
        transition: transform .1s, opacity .2s;
        bottom: 85%;
        pointer-events: none;
        box-shadow: $grayShadow1;
    }

    .vjs-control.vjs-close-button {
        right: 4em;
        top: 4.2em;
    }

    div.vjs-control-bar {
        align-items: flex-end;
        height: 80px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 10.03%, rgba(0, 0, 0, 0.09) 25.75%, rgba(0, 0, 0, 0.21) 49.84%,rgba(0, 0, 0, 0.55) 100%);

        .vjs-control {
            height: 40px;

            &.vjs-progress-control {
                position: absolute;
                bottom: 33px;
                left: 0;
                width: 100%;
                height: 17px;
            }
        }
        .vjs-play-control {
            margin-left: 15px;
        }
        .vjs-fullscreen-control {
            margin-right: 15px;
        }
        .vjs-load-progress {
            div {
                background-color: $white;
                opacity: .6;
            }
        }
    }

    .vjs-button>.vjs-icon-placeholder:before {
        font-size: 2.3em;
        @media #{$IE} {
            font-size: 24px;
        }
    }

    div.vjs-time-control {
        font-size: 1.3em;
    }

    .vjs-progress-control {
        &:hover {
            .vjs-play-progress {
                div.vjs-time-tooltip {
                    visibility: hidden;
                }

                &::before {
                    content: "\F111";
                }
            }
        }

        .vjs-play-progress {
            background-color: $orange;

            &::before {
                content: "";
                font-size: .75em;
                top: -.26em;
            }
        }
        .vjs-progress-holder {
            background-color: $greyDarker;
        }
    }

    &.video-js-blueProgress {
        .vjs-progress-control {    
            .vjs-play-progress {
                background-color: $blue;
            }
        }
    }

    .vjs-volume-panel.vjs-hover {
        .vjs-volume-control.vjs-volume-horizontal {
            height: 4em !important;
        }
    }
    .vjs-text-track-cue {
        top: 90% !important;
    }

    &.vjs-fullscreen {
        .vjs-control-bar {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 10.03%, rgba(0, 0, 0, 0.09) 25.75%, rgba(0, 0, 0, 0.21) 49.84%,rgba(0, 0, 0, 0.55) 100%);
        }
    }

    &.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
        transition: visibility .5s,opacity .5s;
    }
    .vjs-text-track-display {
        transition: bottom .7s;
    }
}

.control-bar__background {
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 50px;
    opacity: .55;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.04) 15.03%, rgba(0,0,0,0.16) 30.75%, rgba(0,0,0,0.4) 49.84%, $black 100%);
}

.vjs-track-settings-controls {
    .vjs-done-button,
    .vjs-default-button {
        padding: 5px 10px;
    }
}

.reload-resolution {
    .vjs-poster {
        display: inline-block !important;
        background-color: black !important;
    }
    .vjs-progress-holder.vjs-slider.vjs-slider-horizontal {
        display: none !important;
    }
}

.asset-detail__preview {
    & > div {
        width: 100%;

        .spinner {
            width: auto;
        }
    }
}

.preview-asset-modal {
    .video-js {
        display: flex;
        justify-content: center;
        align-items: center;

        video {
            top: initial;
            left: initial;
        }
    }
    @media #{$IE} {
        .video-js {
            display: block;
        }
    }
}

.vjs-control .vjs-button {
    text-align: center;
}

.isCVPReview {
    &.video-js {
        .vjs-tech {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.video-poster-duration {
    display: block;
    color: $white;
    margin-top: -27px;
    position: relative;
    text-align: right;
    margin-right: 8px;
    float: right;
    padding: 4px;
    background: $blackLight;
    opacity: 0.8;
    border-radius: 2px;
}

.vjs-menu li .vjs-menu-item-text {
    text-transform: lowercase;
}

