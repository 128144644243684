.asset-content-preview-page-wrapper {
    @media #{$IE} {
        display: flex;
        flex-direction: column;
    }
}

.asset-content-preview-page {
    min-height: 100vh;
    #main {
        background: $white;
        flex-grow: 1;
    }
    .assets-region__nothing-found {
        padding: 60px calc(#{$indentBasis} * 3);
    }
}

.asset-preview-controls {
    display: flex;
    justify-content: space-between;

    margin-bottom: 25px;
}

.page--asset-preview {
    #page {
        display: flex;
        flex-direction: column;
    }
    article #page {
        position: unset;
    }
    .nothing-found {
        padding-top: 20px;
        .assets-region__nothing-found {
            color: $greyDarker4;
            width: 50%;
        }
    }
    .cabinet-main-container {
        padding: 0;
    }

    .search-item-content--other-info {
        width: 100%;
    }

    .asset-preview-controls {
        & > div:first-child {
            width: 100%;
        }
    }
}

.page--video-embed body #app .content-preview-wrapper #page div.video-js {
    height: calc(100% - 245px) !important;
    min-height: 300px;
}

@media screen and (max-width: 639px) and (min-height: 590px) {
    .page--video-embed body #app .content-preview-wrapper #page .video-js {
        height: calc(100% - 320px) !important;
    }
}

.content-preview-footer {
    background: $greyLight2;
    color: $white;
    a {
        color: $greyDarker3;
        font-size: 14px;
        line-height: 24px;

        &:hover {
            color: $greyDarker3;
        }
    }
    &__divider {
        display: none;
    }
    &__content {
        min-height: 184px;
        padding: 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        &__links {
            &__items {
                display: flex;
                padding-left: 0;
                margin: 0;
                list-style-type: none;
                color: $white;
                .footer-link-item {
                    margin-right: calc(#{$indentBasis} * 3);
                }
            }
        }
        &__socials {
            .sapdx-follow {
                display: flex;
                align-items: center;
            }
        }
    }
}

.asset-preview-wrapper {
    .search-item-content--other-info .other-info-box.type:before {
        display: none;
    }
}

.asset-relations--container {
    border-top: $classicBorder;
    padding-top: 15px;
    margin-top: 15px;

    .asset-relations__search-nothing {
        font-size: 14px;
    }

    h3 {
        font-size: 14px;
        font-family: $BrandMedium;
        margin-bottom: 10px;
    }

    .related-asset__wrapper {
        border: 0;
    }
}

@import "./asset_content_preview_responsive.scss";
