.content-store-management-page {
    .content-store-management-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .simple-tooltip-wrapper {
            margin-left: calc(#{$indentBasis} * 2);
        }
    }

    .dt--content-stores {
        .image-container {
            width: 32px;
            height: 32px;
            padding: 5px;
            border-radius: 50%;
            border: $classicBorder;
            overflow: hidden;
            box-sizing: border-box;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .dt__item {
            height: 40px;
            max-height: 40px;
        }
        .dt-cell--checkbox {
            display: none;
        }
        .dt-cell--icon {
            padding: 4px 10px 4px 0;
            flex-basis: 55px;
            min-width: 55px;
            max-width: 55px;
        }
        .dt-cell--title {
            .button--action {
                max-width: 100%;
                span {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
        .dt-cell--topFoldersAmount {
            text-align: center;
            flex-basis: 95px;
            min-width: 95px;
            max-width: 95px;
        }
        .dt-cell--creationDate {
            text-align: center;
            justify-content: center;
            .filter-form-wrap {
                width: auto;
            }
        }
        .dt-cell--actions {
            flex-basis: 40px;
            min-width: 40px;
        }
        .asset-actions-popup {
            width: 220px;
            right: 30px;
        }
    }
}

.content-store-form {
    .input-pair__title-box {
        color: $blackLight;
        &__text {
            position: relative;
        }
        .info-tooltip {
            position: absolute;
            top: -6px;
            right: -32px;
        }
    }
    .base-radio,
    .anime-toggle__label {
        margin: 0;
    }
    .input-pair {
        margin-bottom: 0;
    }

    .anime-toggle__label {
        width: 77px;
    }

    .anime-toggle--checked {
        .anime-toggle__no {
            left: 53px;
        }
    }    
}

.content-store-details {
    .details-header {
        padding: 0 0 16px;
        margin-bottom: 20px;
        border-bottom: $classicBorder;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .details-image-container {
            width: 24px;
            height: 24px;
            padding: 2px;
            margin-right: 8px;
            border-radius: 50%;
            flex-shrink: 0;
            border: $classicBorder;
            overflow: hidden;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .details-title {
            font-size: 16px;
            line-height: 24px;
            color: $blackLight;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .details-wrapper {
        .details-item {
            margin-bottom: 14px;
            font-size: 14px;
            line-height: 17px;
            display: flex;
        }
        .details-label {
            width: 170px;
            margin-right: 10px;
            flex-shrink: 0;
            color: $greyDarker3;
        }
        .details-value {
            color: $blackLight;
        }
        .user-details {
            margin-top: 8px;
            &:first-child {
                margin-top: 0px;
            }
        }
    }
}

.reorder-content-stores-modal {
    &.aside-modal {
        max-width: 240px;
        @include asideModalAnimation(240px);
        .aside-modal__header {
            padding-left: 16px;
            padding-right: 16px;

            .aside-modal__title {
                font-size: 14px;
                line-height: 24px;
            }
        }
        .aside-modal__body {
            padding-left: 0;
            padding-right: 0;
        }
        .reorder-content-stores-body {
            .description {
                padding: 0 16px 16px;
                font-size: 12px;
                line-height: 18px;
                color: $greyDefault;
            }
        }
    }
    .content-store-list {
        border-top: $classicBorder;
        .content-store-item {
            padding: 4px 16px 4px 14px;
            font-size: 14px;
            line-height: 24px;
            color: $greyDefault;
            border-bottom: $classicBorder;
            display: flex;
            &.draggable {
                opacity: 0;
            }
            &.not-active-dragged {
                display: none;
            }
        }
        .drag-icon {
            margin-top: 7px;
            svg {
                width: 8px;
                path {
                    fill: $greyDarker4;
                }
            }
        }
        .image-container {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            margin-left: 12px;
            padding: 5px;
            border-radius: 50%;
            border: $classicBorder;
            overflow: hidden;
            flex-shrink: 0;
            box-sizing: border-box;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .content-store-title {
            margin-top: 4px;
        }
    }
}
