$gridItemBorderWidth: 3px;

.assets-grid {
    border-top: 7px solid $navy;
    padding-top: 15px;
}

.assets-grid--items-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -5px;
    margin-right: -5px;
}

.assets-grid__item,
.folder_grid_item {
    flex: 0 1 219px;
}

.assets-grid__item__inner {
    border: $gridItemBorderWidth solid $greyDarker4;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    background: $greyLight7;
}

.assets-grid__item__info-container {
    min-height: 150px;
    padding: 7px;
}

.assets-grid__item__info-container--selected {
  background-color: $greyLight7;
}

.assets-grid__item__full-description {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 13;
    background: $greyDefault;
    padding: 22px 20px 10px 20px;
    font-family: Arial, sans-serif !important;
    color: $greyDarker4;
    overflow-y: auto;
    word-wrap: break-word;
}

.assets-grid__item__full-description__close {
    position: absolute;
    top: 5px;
    right: 5px;

    svg {
        width: 13px;
        height: 13px;

        path {
            fill: $white;
        }
    }
}

.assets-grid__item {
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;

    .asset-usage {
        position: absolute;
        height: 26px;
        padding: 5px 10px 0 10px;
        box-sizing: border-box;
        text-transform: capitalize;
        width: calc(100% + (2 * #{$gridItemBorderWidth}));
        text-align: left;
    }

    .tile-request-brand-feedback {
        position: absolute;
        left: 35px;
        bottom: 3px;
        width: 60px;
        height: 18px;

        &:hover {
            background-color: $greyLight7;
            cursor: pointer;

            .asset-actions__item__tooltip {
                visibility: visible;
            }
        }
    }

    .asset-actions-popup {
        .copy-url-tooltip {
            width: calc(100% + 24px);
            display: block;
            top: 25px;
            left: 0;
            box-sizing: border-box;
            margin: 0 -12px;

            &.copy-url-tooltip--hidden {
                display: none;
            }

            .copy-url-tooltip--unonimous--link, .copy-url-tooltip--Internal--link, .copy-url-tooltip--profile--link {
                word-wrap: break-word;
                display: block;
                margin-top: -12px;
                line-height: 20px;
            }
        }
    }
}

.assets-grid__item__type {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 1.5px 5px !important;
}

.assets-grid__item__image {
    height: 107px;
    width: 100%;
    display: table;
    table-layout: fixed;
    border-top: 3px solid $greyLight7;

    &--inner {
        height: 103px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
        }
    }



    &.generated_thumbnail {
        .assets-grid__item__image--inner {
            img {
                max-height: 70%;
            }
        }
    }
}

.assets-grid__item__preview__row {
    position: relative;
}

.assets-grid__item__info-item {
    padding: 3px 0 1px;
    line-height: 1.2;
    overflow: hidden;

    > button,
    .show-full-description-button {
        color: $blue;
        font: normal 11px Arial, sans-serif;
        margin-left: 3px;
    }
}

.assets-grid__item__info-item--downloads {
    position: absolute;
    bottom: 8px;
    left: 8px;

    span:first-child {
        @extend .assets-grid__item__info-item__title;
    }
}

.assets-grid__item__info-item__title {
    @extend %tahoma-font;
    font-size: 9px;
    color: $greyDefault;
    text-transform: uppercase;
    display: inline-block;

    & + span {
        display: inline;
    }
}

.assets-grid__item__preview__row--preview-shown {
    background-color: $white;
    padding-top: 23px;

    .asset-usage {
        top: -3px;
        left: -3px;
    }
}

.assets-grid__item__preview__row--preview-hidden {
    .assets-grid__item__image {
        display: none;
    }
    .asset-usage {
        position: relative;
        width: 102%;
        text-align: left;
        top: -2px;
        right: -2px;
        left: -2px;
    }
    .assets-grid__item__type {
        top: 4px;
        right: 4px;
    }
}

.assets-grid__item__preview__row--preview-hidden + .assets-grid__item__actions-row {
    margin-top: 24px;
}

.assets-grid__item__actions-row {
    @extend %clearfix;
    line-height: 0;
    padding: 7px;


    .asset-actions {
        float: right;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
    }
}

.assets-grid__item__dates-row {
    padding-top: 3px;
    display: flex;
    justify-content: space-between;

    .assets-grid__item__info-item {
        display: inline-block;
        width: 50%;

        &:last-child {
            width: 40%;
        }

        .assets-grid__item__info-item__title {
            display: block;
        }
        .assets-grid__item__info-item__text{
            font: normal 11px Arial, sans-serif;
            color: $defaultColor;
            line-height: 1.6;
        }
    }
}

.assets-grid__item__info-item__link {
    color: $blue;
}

.assets-grid__item__subscription {
    margin-top: 4px;
    margin-right: 10px;
    float: right;
}

// Region media queries

@media (max-width: 400px) {
    .folder_grid_item,
    .assets-grid__item {
        width: 100%;
        flex: 0 1 100%;
        max-width: 100%;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .folder_grid_item,
    .assets-grid__item {
        width: 50%;
        flex: 0 1 50%;
        max-width: 50%;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .folder_grid_item,
    .assets-grid__item {
        width: 33.33%;
        flex: 0 1 33.33%;
        max-width: 33.33%;
    }
}

@media (min-width: 700px) and (max-width: 870px) {
    .folder_grid_item,
    .assets-grid__item {
        width: 33.33%;
        flex: 0 1 33.33%;
        max-width: 33.33%;
    }
}

@media (min-width: 870px) {
    .folder_grid_item,
    .assets-grid__item {
        width: 25%;
        flex: 0 1 25%;
        max-width: 25%;
    }
}

@media (min-width: 1000px) {
    .assets-grid__item,
    .folder_grid_item {
        width: 25%;
        flex: 0 1 25%;
        max-width: 25%;
    }
}

@media (min-width: 1200px) {
    .assets-grid__item,
    .folder_grid_item {
        width: 20%;
        flex: 0 1 20%;
        max-width: 20%;
    }
}


@include media-ipad-landscape {
    .folder_grid_item,
    .assets-grid__item {
        flex: 0 1 20%;
        width: 20%;
        max-width: 20%;
    }
    .dash-boxes-container {
        .folder_grid_item,
        .assets-grid__item {
            flex: 0 1 20%;
            width: 20%;
            max-width: 20%;
        }
    }

}

@include media-ipad-portrait {
    .folder_grid_item {
        flex: 0 1 25%;
        width: 25%;
        max-width: 25%;
    }

    .dash-boxes-container {
        .assets-grid__item,
        .folder_grid_item {
            flex: 0 1 25%;
            width: 25%;
            max-width: 25%;
        }
    }
}

// End region media queries
