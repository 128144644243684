.search-results-info {
    &--sorting {
        & > div {
            display: flex;
        }

        .dropdown-body {
            left: 0;
            z-index: 3;

            .dropdown-body__content__items {
                &-title {
                    font-size: 10px;
                    color: $greyDarker3;
                    font-family: $BrandMedium;
                    line-height: 24px;
                    margin-bottom: 0;
                    padding: 6px calc(#{$indentBasis} * 2);
                    cursor: default;

                    &:hover {
                        background: none;
                    }
                }
            }

            .button--action {
                text-transform: capitalize !important;
            }
        }
    }
}