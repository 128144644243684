$previewWidth: 600px;
$previewHeight: calc(#{$previewWidth} / #{$previewRatio});

.button--action.round-button {
    border-radius: 16px !important;
    background-color: white;
}

.input-pair {
    display: block;

    &__title-box {
        display: block;
        float: none;
    }

    &__input-box {
        display: block;

        .error {
            top: 100%;
        }
    }

    &.expiration-date {
        .input-pair__input-box {
            .bound-datepicker {
                width: 170px;
            }
            .base_block_select {
                margin-top: $indentBasis;
            }
        }
    }
}

.personal-data-field .personal-data__checkbox-area {
    max-width: none;
    margin-bottom: 10px;
}

.preview-asset-modal {
    color: $greyDefault;
    .asset-actions-popup {
        z-index: 1005;
    }
    .loading-message {
        margin-top: 55px;
        font-size: 24px;
        text-align: center;
    }
    .preview-modal {
        height: inherit;

        &__actions-bar {
            width: 40px;
            border-right: 1px solid $hoverBg;
            border-top: 0;
            @media #{$IE} {
                flex-shrink: 0;
            }
            &--disabled {
                button {
                    pointer-events: none;

                    svg {
                        path {
                            fill: $blueLight5!important;
                        }
                    }

                    .material-icons {
                        color: $blueLight5;
                    }


                }

                .asset-actions__item__tooltip {
                    display: none !important;
                }
            }

            & > div > .asset-actions { //for do not effect the actions in popup? mobile/carousel view
                display: flex;
                flex-direction: column;
                padding-top: 10px;

                &__item--container {
                    float: none;
                }

                .asset-actions__links {
                    padding: 10px 0 0 0;
                    border-top: 1px solid $greyLight7;
                    float: none;
                }

                .button--action {
                    width: 34px;
                    height: 34px;
                    padding: 10px;
                    margin: 0 0 10px 3px;
                    border-radius: 3px;
                    overflow: visible;
                    box-shadow: none;

                    svg {
                        width: 15px;
                        height: 15px;
                        display: block;

                        path {
                            fill: $blackLight;
                        }
                    }

                    .material-icons {
                        margin-right: 0;
                        font-size: 17px;
                    }

                    &:not(:disabled):hover {
                        background: $greyDarker2;
                    }

                    .button--action__toolTip {
                        z-index: 5;
                        text-transform: none;
                    }
                }
            }

            .copy-url-tooltip {
                .asset-actions__item__icon {
                    padding: 0;
                    width: auto;
                    height: auto;
                    margin-right: 10px;

                    svg {
                        margin: 0;
                    }
                }

                .asset-actions__item--icon-mode {
                    margin: 0;
                }
            }
        }
        &__body {
            width: 100%;
            display: flex;
            flex-direction: column;

            .edit_asset_container {
                height: calc(100% - 76px);

                .manage-asset-form {
                    height: 100%;
                }

                &--buttons {
                    display: flex;
                    // justify-content: flex-end;
                    position: relative;
                    padding: 2*$indentBasis 3*$indentBasis;
                    border-top: $classicBorder;
                    margin: 3px -3*$indentBasis 0;
                    // .button--action {
                    //     width: 100%;
                    //     margin: 0;
                    //     border-radius: 0;
                    // }

                    .react-select-box-container {
                        width: auto;
                        margin-right: 10px;
                    }

                    .react-select-box {
                        width: 150px;
                        height: 33px;
                        &--opened {
                            position: relative;
                            width: 150px;
                        }
                    }
                }
            }

            .notice-in-header{
                min-height: 100px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;

                .upload-asset-modal__btn-group {
                    top: 58px;
                    right: 6px;
                }
                .edit_asset_container--header--left {
                    top: 58px;
                    position: absolute;
                }
            }

            .preview-modal__header {
                position: relative;
                border-bottom: 1px solid $greyLight7;
                padding: 5px 20px 0 20px;
                min-height: 45px;

                &__nav-tabs {
                    padding: 0;
                    margin: 0;
                    margin-left: 20px;

                    .nav-link {
                        list-style-type: none;
                        display: inline-block;

                        button {
                            background: none;
                            box-sizing: border-box;
                            display: block;
                            text-decoration: none;
                            font-size: 14px;
                            line-height: 23px;
                            text-align: center;
                            color: $black;
                            padding: 10px 20px;
                            border: 0;
                            border-bottom: 2px solid $white;
                            font-family: $BrandRegular;
                            text-transform: capitalize;
                            @extend %hover-bg-annimation;

                            &:hover {
                                background: $greyLight7;
                                border-color: $greyLight7;
                            }
                        }
                        &--active {
                            button {
                                color: $blackLight;
                                border-bottom: 2px solid $blackLight;
                                font-family: $BrandMedium;
                                cursor: default;

                                &:hover {
                                    background: none;
                                    border-color: $blackLight;
                                }
                            }
                        }

                        &--invalid {
                            button {
                                border-color: red;
                                color: red;

                                &:hover {
                                    border-color: red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.edit-mode {
        .preview-modal {
            display: flex;

            .preview-modal__body .notice-container {
                margin: 0;
            }

            .asset-preview {
                height: calc(100% - 75px);
            }
        }
    }

    &.preview-mode {
        .asset-preview__section {
            padding-top: 8px;
            word-break: break-all;

            &:first-child {
                & > h2 {
                    display: none;
                }
            }
        }
    }

    .pdf-preview-container-new {
        .page {
            position: relative;
            top: -25px;
        }
    }
}

.playlist-info-modal.data-not-loaded,
.preview-asset-modal .preview-modal.data-not-loaded {
    .asset-preview__heading,
    .asset-preview__title,
    .asset-preview__data,
    .asset-detail__preview,
    .asset-preview__description,
    .asset-preview__name,
    .asset-preview__folder-name {
        @extend %preload;
        min-height: 20px;
    }

    .preview-container,
    .asset-usage,
    .collapse,
    .add-to-workspace,
    .statistics-content,
    .asset-actions-trigger {
        display: none;
    }

    .preview-modal__body .preview-modal__header__nav-tabs {
        min-height: 45px;
        li {
            display: none;
        }
    }

    .asset-preview-metadata__pair {
        margin-bottom: 8px;
    }

    .asset-preview__title {
        width: 210px;
    }
    .asset-preview__data {
        width: 100%;
        margin-left: 10px;
    }
}

.statistics-modal-link {
    height: 40px;
}

.top-panel-container {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    .top-panel-container--left {
        display: flex;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        width: 100%;

        & > * {
            margin-left: 16px;
            border-left: 1px solid $black;
            padding-left: 16px;

            &:first-child {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }
        }

        .search-item-content {
            width: 100%;
        }
    }

    .statistics-content {
        float: none;
        .dropdown-title {
            height: 34px;
            border-radius: 3px;
            background-color: transparent;
            border-color: transparent;
            color: $blackLight;
            font-family: $BrandRegular;
            font-size: 12px;
            font-weight: bold;
            line-height: 17px;
            width: 135px;
            box-shadow: none;
            margin-top: 5px;
            margin-right: -12px;

            &:hover {
                background: $hoverBg;
                border-color: $hoverBg;
            }
        }

        .statistics-chart {
            width: 530px;
            height: 235px;
            border: 1px solid $greyDarker4;
            border-radius: 3px;
            box-shadow: $grayShadow1;
        }

        .dropdown-body {
            margin-top: 5px;
            right: -12px;
            z-index: 3;
        }

        .dropdown-body > *:hover {
            background: $white;
            transition: .2s;
        }
        &.opened {
            .dropdown-title {
                background-color: $hoverBg;
                border-color: $hoverBg;
            }
        }
    }

    .dropdown-body .asset-actions button {
        padding: 3px 13px;
        span {
            padding-left: 3px;
        }
    }

    .button--action__toolTip {
        &.button--action__toolTip--top {
            left: -24px;
            right: auto;

            &:after {
                top: 22px;
                left: 98px;
                right: auto;
            }
        }
    }

    .language-field {
        i {
            margin-top: 1px;
        }
    }

    .asset-usage {
        text-transform: none;
        background-color: transparent;

        &--direct {
            svg path {
                fill: #398C2B;
            }
        }
        &--internal {
            svg path {
                fill: $orange;
            }
        }
        &--partner {
            svg path {
                fill: $blue;
            }
        }
    }
}

.html_link_wrapper {
    .html_link, .html_link .button-actions {
        height: 18px;
        padding: 0;

        .dropdown-title {
            height: 18px;
            border: none;
            box-shadow: none;
            color: $blue;
            font-family: $BrandMedium;
            padding: 0;
            background: none;
            font-size: 12px;
            .material-icons {
                color: $blue;
            }
            &:hover {
                background: none;
                border: none;
            }
        }
    }
}

.asset-preview {
    padding: 5px 20px 70px 20px;
    height: calc(100% - 120px);
    width: 519px;
    overflow-y: scroll;
    overflow-x: hidden;
    word-wrap: break-word;

    &__content {
        min-height: calc(100vh - #{$previewHeight});
        background-color: $white;

        .asset-preview__metadata {
            padding: 0 3*$indentBasis;
        }
    }

    .compliance_requirements {
        margin-bottom: 10px;
    }

    &__section {
        margin: 0 4px 15px;
    }

    &__first {
        margin-bottom: 16px;
        .asset_actions_dropdown {
            height: 24px;
            .dropdown-title {
                margin-top: 0;
            }
        }
    }

    .add-to-workspace {
        margin: 15px 0;
        position: static;
    }

    .tabs__panel__asset-versions {
        &--body-item-link {
            display: flex;
            align-items: center;
            .button--action__toolTip{
                left: 87px;
                &:after {
                    left: 18px;
                }
            }
        }
    }

    &__thumbnail {
        min-height: $previewHeight;
        justify-content: center;
        overflow: hidden;
        position: relative;

        .download_prev_version {
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 10;
        }

        .close-button {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 10;
            border: none;
            box-shadow: none;
            svg {
                path {
                    fill: $greyBlueDark;
                }
            }
        }

        .asset-detail__preview {
            width: $previewWidth;
            height: $previewHeight;

            &.crop-mode {
                height: $previewHeight;

                .buttons-panel {
                    justify-content: flex-end;
                    padding-right: 62px;
                    padding-top: 8px;
                }
            }

            & > {
                height: 100%;
            }
        }
    }

    &__name {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 30px;
        font-family: $BrandMedium;
        color: $blackLight;
        word-break: break-word;

        b {
            font-family: $BrandBold;
            color: $black;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 21px;
        color: $blackLight;
        font-family: $BrandRegular;
        word-break: break-word;

        b {
            font-family: $BrandBold;
            color: $black;
        }

    }

    &__tab {
        padding: 16px 0;
        border-top: $classicBorder;

        .collapse {
            padding: 4px 0;

            &.collapse--opened {
                padding: 0;
            }
        }

        .collapse-button {
            padding: 0;
        }
    }

    &__heading {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: $blackLight;
        text-transform: capitalize;
        font-family: $BrandMedium;
        margin-bottom: 8px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &-metadata__pair {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__subtitle-pair {
        margin-bottom: $indentBasis;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
    }

    &__folder-pair {
        padding: 4px 0;
        margin-bottom: 4px;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__folder-name {
        margin-left: 10px;
    }

    &__folder-button.button--action {
        margin-left: -5px;

        &:not(.dropdown-title):not(.button--action) {
            margin-top: -2px;
        }
    }

    &__title {
        padding: 4px 4px 4px 0;
        font-size: 14px;
        text-transform: none;
        line-height: 20px;
        word-break: break-word;
        color: $greyDarker3;
        width: 210px;
        font-family: $BrandRegular;
        flex-shrink: 0;
        flex-grow: 0;
        box-sizing: border-box;

        &.asset-preview__folder-name {
            padding: 0;
        }
    }

    &__new-data {
        border-radius: 2px;
        background: $greyLight2;
        font-family: $BrandMedium;
        color: $saladGreenDark;

        .asset-preview__data {
            padding: 0px 4px;
            color: $saladGreenDark;
        }
    }

    &__value {
        padding: 0px 4px;
    }

    &__subcontent {
        .asset-preview__new-data {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: calc(#{$indentBasis} / 2);
            padding-left: 4px;
            padding-bottom: calc(#{$indentBasis} / 2);
            margin-bottom: 0;
        }
    }

    &__data {
        padding: 4px 0px 4px 4px;
        line-height: 21px;
        color: $blackLight;
        font-size: 14px;
        word-break: break-word;
        box-sizing: border-box;

        &.asset-preview__folder-name {
            padding: 0;
        }

        &-item {
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        &-list {
            padding: 0 4px;
            .collapse {
                padding: 4px 0;
                border-top: none;
                &.collapse--opened {
                    padding: 0 0 4px;
                    .collapse-button {
                        display: none;
                    }
                }
                .collapse-button {
                    width: auto;
                }
                .collapse-toggle {
                    position: relative;
                    display: flex;
                    flex-direction: row-reverse;
                    .collapse-toggle__title {
                        font-family: $BrandRegular;
                        font-size: 14px;
                        line-height: 21px;
                        color: $blue;
                    }
                    .collapse-toggle__icon {
                        margin-left: $indentBasis/2;
                        top: 0px;
                        .collapse-toggle__icon__arrow {
                            transform: rotate(90deg);
                            svg {
                                path {
                                    fill: $blue;
                                }
                            }
                        }
                    }
                }
                .collapse-body {
                    padding: 3px 0 0 0;
                }
            }
        }
    }

    &__folder-name:after {
        content: '/';
        margin: 0 1px 0 5px;
    }

    &__folder-name:last-child {
        font-family: $BrandMedium;
        &:after {
            display: none;
        }
    }

    &__search-details {
        padding: 16px 0;
        margin-bottom: 16px;
        border-top: $classicBorder;
        border-bottom: $classicBorder;

        h2 {
            margin-bottom: 12px;
        }

        .collapse.collapse--with-arrow {
            margin: 0;
            padding: 0;
            border: 0;

            .collapse-button {
                background: $white;
                padding: 4px 0 4px 7px;
                border: none;
            }

            .collapse-toggle {
                align-items: center;
                .collapse-toggle__title {
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $BrandRegular;
                    text-transform: none;
                    color: $blackLight;
                }

                .collapse-toggle__icon {
                    top: 0;
                }
            }

            .collapse-content {
                padding: 0;
                font-size: 14px;
                line-height: 21px;
                color: $blackLight;

                .collapse-content-description {
                    padding: 4px 0;
                }

                p.asset-preview__data {
                    padding: 0;
                }

                b {
                    font-family: $BrandMedium;
                }
            }
        }
    }

    &__switcher {
        padding: $indentBasis calc(#{$indentBasis} * 3);
        border-bottom: $classicBorder;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-column {
            display: flex;
            align-items: center;

            &.gray {
                color: $greyDarker3;
            }

            svg {
                margin-right: calc(#{$indentBasis} / 2);
                path {
                    fill: $greyDarker3;
                }
            }

            .base-input {
                margin-left: $indentBasis;
            }
        }
    }
}

.ready-to-crop {
    .asset-preview__thumbnail {
        overflow: visible;
        position: relative;
    }

    .tabs {
        overflow: visible !important;
    }

    .asset-preview {
        overflow: visible !important;
    }
}

.page.asset-preview-page {
    .asset-preview-page--actions-holder {
        .download-hacky-parent {
            margin-right: calc(#{$indentBasis} * 2);
        }

        .asset-actions-popup {
            right: 0;
            top: 45px;
        }
    }
    .asset_preview_page--content {
        position: relative;
        margin: 40px auto;
        padding: 0;
        width: $previewWidth;
        max-width: calc(100vw - 40px);
        background-color: $white;
        border: $classicBorder;
        border-radius: 4px;

        .preview-modal__body {
            padding: 0 32px;
        }

        .asset-preview__thumbnail {
            margin-bottom: 5px;
            border-radius: 4px 4px 0 0;
        }

        .asset-actions__item--icon-mode {
            display: none;
        }

        .asset-actions__item--popup-mode {
            display: block;
        }

        .asset-preview-page--actions-holder {
            display: flex;
            justify-content: flex-end;

            .bulk-actions {
                margin-right: 0;
                z-index: 2;
            }

            .bulk-actions--dropdown {
                min-width: 0;
                margin-left: 10px;

                .dropdown-title {
                    color: $blackLight;
                    height: 32px;
                    background: $white;

                    &:after {
                        border-top-color: $greyDarker3;
                    }

                    &:hover {
                        background: $hoverBg;
                    }
                }

                &.opened {
                    .dropdown-title {
                        background: $greyDarker2;
                    }
                }

                .dropdown-body {
                    width: 290px;
                    right: 0;
                    left: auto;

                    .dropdown-body__content__items > *:hover {
                        background: transparent;
                    }

                    .asset-actions-bar-mobile {
                        padding: 0;
                    }

                    .download-hacky-parent {
                        display: none;
                    }

                    .asset-actions {
                        padding: 0;

                        .button--action {
                            width: 100%;
                            svg {
                                align-self: baseline;
                                padding-top: 5px;
                            }
                            span {
                                line-height: 26px;
                            }
                        }

                        &__item--container {
                            width: 100%;
                        }

                        &__item--popup-mode {
                            display: block;

                            .button--action {
                                white-space: normal;
                                min-height: 30px;
                                height: auto;
                            }
                        }

                        &__item--icon-mode {
                            display: none;
                        }

                        &__links {
                            padding-left: 0;
                        }

                        &__item,
                        &__links {
                            width: 100%;
                        }
                    }

                    & > *:hover {
                        background: none;
                    }
                }
            }
        }
    }

    .statistics-modal-link,
    .statistics-content {
        display: none;
    }

    .asset-preview-page--header {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        position: relative;
        z-index: 1;
    }

    .preview-modal__header__nav-tabs {
        display: none;
    }

    .preview-modal__header {
        display: none;
    }

    .asset-preview__section {
        min-height: 0 !important;

        .asset-preview__heading {
            border: $classicBorder;
            padding: 5px 10px;
            margin: 0 0 15px 0;
        }

        &:first-child {
            & > .asset-preview__heading {
                display: none;
            }
        }
    }

    .asset-preview {
        padding-left: 10px;
        padding-right: 10px;
        width: $previewWidth;
        max-width: calc(100vw - 35px);
        overflow-y: auto;

        // remove scroll in the bottom of page
        .asset-preview__search-details {
            overflow: hidden;
        }

        .preview-container {
            position: relative;

            .spinner {
                height: 100px;
                margin-top: -50px;
            }
        }
    }
}


.default-thumbnail {
    display: flex;
    justify-content: space-around;
    align-items: center;
    vertical-align: middle;

    img.content-preview--image:not(.is-square) {
        width: 25%;
    }
}

.asset-image-wrapper.is-message {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: $greyLight2;
    color: $greyDarker3;
    text-align: center;
}

.preview-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .asset-image-wrapper {
        &.logo-treatment {
            background: $white;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 90% !important;
                height: auto !important;
            }
        }

        &.is-square-folder-view:not(.default-thumbnail) {
            display: flex;
            justify-content: space-around;
            vertical-align: middle;

            img.content-preview--image {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                object-fit: contain;
            }
        }
    }

    .pdf-preview-container-new {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    img.content-preview--image {
        width: 100%;
        height: auto;
        max-width: none;
        max-height: none;
        min-width: 0;
        min-height: 0;

        &.is-square {
            width: auto;
            height: 100%;
        }
    }

    @include centeredSmallThumbnail();

    &-gif {
        position: relative;
        .preview-gif-btn {
            width: 50px;
            height: 50px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
            cursor: pointer;
            opacity: 1;
            border: 6px solid $white;
            background-color: $greyDarker3;
            background-color: rgba(43,51,63,.7);
            border-radius: 50%;
            transition: all .4s;
            box-sizing: content-box;

            &:before {
                content: "\f101";
                width: 100%;
                height: 100%;
                font-family: 'VideoJS', Arial, sans-serif;
                font-size: 30px;
                line-height: 45px;
                font-weight: 400;
                color: $white;
                font-style: normal;
                text-align: center;
                position: absolute;
                top: 3px;
                left: 0;
            }

            &:hover {
                border-color: $white;
                background-color: rgba(115,133,159,.5);
            }
        }
    }
}

img.content-preview--image {
    &.not-loaded {
        visibility: hidden;
    }
}

.dummy.isMobileOnly {

    .asset-preview {
        width: auto;

        &__data {
            width: 50%;
            word-wrap: break-word;
        }

        .asset-preview-metadata__pair {
            display: block;

            .asset-preview__data,
            .asset-preview__title {width: 100%}
        }
    }

    .statistic-container {
        display: block;

        .statistics-content {
            .statistics-chart {
                width: 300px;
            }
        }
    }

    .preview-asset-modal .preview-modal__body .preview-modal__header__nav-tabs .nav-link button {
        padding: 10px;
        font-size: 12px;
    }

    .preview-asset-modal .preview-modal__body .preview-modal__header__nav-tabs {
        margin-left: 10px;
    }
}

.dummy.isIE {
    .asset-preview__section {
        &:last-child {
            min-height: 100vh;
        }
    }
}

.aside-modal .tabs {
    margin-top: -46px;
}

.asset-preview-page .tabs {
    margin-top: -25px;
}

.notice-in-body{
    .edit_asset_container{
        height: calc(100% - 205px) !important;
    }
}

.asset-actions-bar-mobile {
    .asset-actions__links {
        padding-left: 0;
    }
}
.notice-container {
    border-top: 1px solid $greyDarker2;
    background-color: $greyLight7;
    height: auto;
    margin: 0 -20px -16px -20px;
    padding: 10px 20px 10px 20px;
    display: flex;
    z-index: 1;
    position: relative;
    .notice{
        height: 20px;
        border-radius: 3px;
        background-color: $red;
        color: $white;
        font-family: $BrandRegular;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding: 0 5px;
    }
    .message{
        color: $greyDefault;
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 22px;
        margin-left: 10px;
    }
}


@import './style_responsive';
