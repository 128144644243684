@media #{$mobile} {
    .edit_asset_container {
        &--header {
            &--left {
                max-width: calc(100% - 35px);
            }
        }
    }
}
@media screen and (min-width: 720px) and (max-width: 1150px) {
    .upload-asset-modal.edit-mode:not(.full-screen) .edit_asset_container--header--left {
        width: 20%;
    }
}

@media screen and (min-width: 720px) and (max-width: 830px) {
    .upload-asset-modal.edit-mode .edit_asset_container--header--left {
        display: none;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header .apply-metadata {
        margin-left: 0;
    }
}


