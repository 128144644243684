html {
    min-height: 100%;
    position: relative;
    background: $greyLight7
}

strong, b {
    font-family: $BrandBold;
}

header {
    z-index: 3;
    position: relative;

    & + div {
        z-index: 3;
        position: relative;
    }
}

article {
    box-sizing: border-box;
    min-width: 320px;
    width: 100%;
    min-height: calc(100vh - 60px);
    margin: 0 auto;
    z-index: 1;
}

label[aria-label] {
    display: block;
}
label.base-checkbox {
    display: inline-flex;
}

.page--width-wrapper {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    justify-content: space-between;
}

.sales {
    article {
        padding: 0;
    }
}

body:not(.user-is-tabbing) {
    * {outline: none !important;}
}

.button--action.skip_button {
    position: fixed;
    top: 10px;
    z-index: 1000000;
    left: -999px;
    text-decoration: none;

    &:focus, &:active {
        left: 10px;
    }
}

.grid-view__wrapper {
    .grid-view__item {
        margin-bottom: 15px;
    }
}

.grid-view {
    .grid-view__wrapper {
        margin: -2px -12px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: calc(100% + 22px);

        .grid-view__item {
            // width: 100%;
            padding: 12px;
            margin-bottom: 0;
            box-sizing: border-box;
        }
    }
}

.grid-view__col-1 {
    .grid-view__item {
        width: 100%;
    }
}

.grid-view__col-2 {
    .grid-view__item {
        width: 50%;
    }
}

.grid-view__col-3 {
    .grid-view__item {
        width: 33.3%;
    }
}

.grid-view__col-4 {
    .grid-view__item {
        width: 25%;
    }
}

.grid-view__col-5 {
    .grid-view__item {
        width: 20%;
    }
}

.grid-view__col-6 {
    .grid-view__item {
        width: 16.6%;
    }
}

.grid-view__col-7 {
    .grid-view__item {
        width: 14.2%;
    }
}

.grid-view__col-8 {
    .grid-view__item {
        width: 12.5%;
    }
}

.grid-view__col-9 {
    .grid-view__item {
        width: 11.1%;
    }
}

.grid-view__col-10 {
    .grid-view__item {
        width: 10%;
    }
}

body {
    .react-select__option.groupOption {
        color: $defaultTextColor;
        font-family: $BrandMedium;

        .pseudo-checkbox {
            display: none;
        }
    }
}

.button--action {
    &.modalBtn {
        width: 100%;
        border-radius: 0;
        height: 40px;
        line-height: 40px;
        font-family: $BrandMedium;
    }
}

.dropdown-body {
    .button--action {
        padding: 0 calc(#{$indentBasis} * 2);

        svg,
        .material-icon {
            margin-right: $indentBasis;
        }

        .right-icon {
            svg {
                margin-right: 0;
            }
        }
    }
}

.active.button--action:not(.dropdown-title) {
    background: $greyLight2 !important;
    font-family: $BrandMedium !important;
}

.async-tabs--wrapper {
    margin-bottom: calc(#{$indentBasis} * 2);

    // TODO: Move to coresap after release
    .async-tabs-container, 
    .async-tabs-container-hidden {
        ul {
            display: flex;
        }
    }
}

.main-content {
    &:focus {
        outline-offset: -3px;
    }
}

.page-header {
    padding-top: 4*$indentBasis;
    padding-bottom: 2*$indentBasis;
    
    &-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        vertical-align: middle;
    }

    h1, h3 {
        display: block;
        padding-top: 0;
        font-family: $BrandMedium;
        font-size: 22px;
        line-height: 32px;
        color: $blackLight;
    }

    &--left {
        margin-right: 2*$indentBasis;
        flex-grow: 0;
        flex-shrink: 0;

        &.page-header-wrapper {
            justify-content: flex-start;
            align-items: center;
        }
    }

    &-buttons {
        display: flex;
        align-items: center;
    }

    &-description {
        margin-top: $indentBasis;
    }
}
