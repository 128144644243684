.new-search-page.content-store-page.simple-view {
    .learning-store--header {
        background-image: url("../../../styles_images/learning_bg.svg");
        background-repeat: no-repeat;
        background-color: $blue;
        padding-bottom: $indentBasis * 8;
        margin-left: -33px;
        margin-right: -33px;
        padding-left: 33px;
        padding-right: 33px;
        margin-bottom: $indentBasis * 3;

        .content-store-header {
            background: none;

            .button--action {
                svg path {
                    fill: $white;
                }

                &:hover {
                    background: $greyDarker3;
                    border-color: $greyDarker3;

                    svg path {
                        fill: $greyDarker2;
                    }
                }

                &:focus {
                    outline-color: $white;
                }
            }
        }
    }
    .content-store-header {
        .content-store-header--action-buttons {
            position: absolute;
            top: 5*$indentBasis;
            right: 3*$indentBasis;
        }
        .content-store-description {
            align-items: center;
            padding-top: $indentBasis;

            .info {
                align-items: center;
                padding-top: 4*$indentBasis;

                .title {
                    font-family: $BrandBold;
                    color: $white;
                    font-size: 28px;
                    line-height: 36px;
                    margin-bottom: $indentBasis * 1;
                }

                .folder-description {
                    margin-top: 0;
                    font-size: 16px;
                    color: $white;
                }
            }

            .content-store__thumbnail {
                display: none;
            }
        }
    }
    .carousel-tree .content-store-header .content-store-description .content-store__thumbnail__icon {
        width: 32px;
    }
    .search-in-store {
        margin-bottom: 0;
        justify-content: center;
    }
    .content-store-header--bottom {
        justify-content: center;

        .content-store-description {
            flex-grow: 0;
            flex-direction: column;
            align-items: center;
        }
    }

    .content-store__thumbnail__icon {
        width: 40px;
        height: 22px;
        background: none;
        border: none;
        padding: 0;
    }

    .saved_search_details .content-store__thumbnail__icon {
        background: white;
        width: 35px;
        height: 35px;
        border: $classicBorder;
        padding: 5px;
        svg {
            width: 15px;
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 1365px) {
    .new-search-page.content-store-page.simple-view .learning-store--header {
        margin-left: -17px;
        margin-right: -17px;
        padding-left: 17px;
        padding-right: 17px;
    }
}
@media #{$mobile} {
    .new-search-page.content-store-page.simple-view .learning-store--header {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .new-search-page.content-store-page.simple-view .content-store-header .content-store-header--action-buttons {
        right: 0;
    }
}

