.form-list_field {
    .white.button--action:not(.dropdown-title) {
        color: $blackLight;
    }
    .form-list {
        margin: 16px 0;
    }

    .list-item {
        height: 37px;
        padding: 10px 10px 10px 0;
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 0.5*$indentBasis;
        >svg {
            margin-right: 2*$indentBasis;
        }

        .item-icon {
            width: 24px;
            height: auto;
            margin-right: 2*$indentBasis;
        }

        .item-close {
            position: absolute;
            top: 6px;
            right: 5px;
            z-index: 1;
        }

        .item-close, .primary_caption_field {
            svg {
                path {
                    fill: $greyDarker;
                }
            }
        }

        .primary_caption_field {
            position: absolute;
            top: 6px;
            right: 40px;
            z-index: 1;
        }
    }
}

.xml-error {
    color: $red;
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    svg {
        width: 13px;
        margin: 1px 5px 0 0;
        path {
            fill: $red;
        }
    }
}

.xml_field {
    .mac-file__titles {
        display: none;
    }
    .form-list_field {
        .form-list {
            margin: 0;
        }
    }
}

.required_caption_checkbox {
    margin: $indentBasis 0;
    .base-checkbox__label {
        padding-top: 0;
    }
}

.required_caption_warning {
    border-radius: 8px;
    background-color: $yellow;
    border: 1px solid #E76500;
    padding: $indentBasis 1.5*$indentBasis;
    margin-top: $indentBasis;
    display: flex;
    svg {
        width: 16px;
        height: 16px;
        margin-right: $indentBasis;
        margin-top: 3px;
        path {
            fill: $orange;
        } 
    }
    &--message {
        line-height: 18px;
    }
}