.dt--requests {
    .dt-cell--comment {
        min-width: 42px;
        flex-basis: 42px;
        .rejection-reason-dropdow--wrapper {float: left}
    }

    .dt-cell--type {
        word-wrap: normal;
    }

    .rejection-reason-dropdown {
        &.dropdown {
            .dropdown-body {
                margin-top: $indentBasis;
            }
            
            .dropdown-body__arrow {
                left: auto;
                right: 20px;
            }
        }
    }
}