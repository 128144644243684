// to position Valid Check Mark
.input-pair {
    position: relative;
}

// to position Required Asterisk
.input-pair__title-box {
    position: relative;
}

.input-pair__input-box .input-pair--invalid {
    flex-wrap: wrap;
    justify-content: flex-end;
}

.input-pair--required .input-pair__title-box__text {
    display: inline-block;
    position: relative;
    &:after {
        content: '*';
        font: 16px $BrandMedium;
        color: $red;
        margin-left: 1px;
        margin-top: -5px;
        display: inline-block;
        position: absolute;
    }
}

.input-pair__input-box__error,
.input-pair .input-pair__input-box .error,
.error {
    display: flex;
    position: relative;
    top: 100%;
    right: 0;
    color: $red;
    font-size: 12px !important;
    line-height: 18px;
    // padding: 2px 5px;
    margin-bottom: 5px;
    z-index: 0;

    &--left {
        float: left;
    }
    .material-icons {
        font-size: 15px;
        color: $red;
        margin-right: 4px;
    }

    svg {
        width: 15px;
        margin-right: 4px;
        path {
            fill: $red;
        }
    }
}

.input-pair--invalid {
    .RichEditor-editor,
    textarea,
    input,
    .react-select-box,
    .Select-control,
    .react-select__control,
    .bound-datepicker,
    .base-tags-input-inner {
        border-color: $red !important;
    }
    .bound-datepicker {
        svg {
            path {
                fill: $red;
            }
        }
    }
}

.react-select__indicator {
    .material-icons {
        font-size: 2.3rem;
    }
}

.copy-button {
    .button--action.link-copied {
        span {
            color: $greyDarker !important;
        }
        svg {
            path {
                fill: $saladGreenDark !important;
            }
        }
    }
}
