%avatar {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $greyLight6;
    border: 1px solid $inputBorderColor;
    border-radius: 50%;
    color: $greyDefault;
    font-family: $BrandMedium;
    font-size: 12px;
    line-height: 32px;
    flex-shrink: 0;
    box-shadow: none;
    box-sizing: border-box;
    transition: border-color .3s;
    &:focus {
        outline-offset: 2px;
    }
}

%avatar-hover {
    border-color: $greyDarker;
}

.user-avatar {
    @extend %avatar;

    &.md-48 {
        width: 48px;
        height: 48px;
        line-height: 48px;
    }

    &.md-24 {
        width: 24px;
        height: 24px;
        line-height: 24px;
    }
}

.user-data {
    display: flex;
    align-items: center;

    .user-avatar {
        margin-right: $indentBasis;
    }

    &-name-wrapper {
        line-height: 24px;
    }

    &__name {
        font-size: 14px;
        font-family: $BrandMedium;
        line-height: 24px;
        color: $greyDefault;
    }

    &__role {
        margin-left: $indentBasis;
        font-size: 12px;
        line-height: 24px;
        color: $greyDarker3;
    }

    &__email {
        font-size: 12px;
        line-height: 12px;
        color: $blue;
        display: block;
    }

    &__id {
        &.button--action {
            margin: 6px auto 0;
        }
    }

    &.vertical {
        display: block;

        .user-data__email {
            margin-top: 2px;
        }
    }
}

.manage-user-item {
    padding: $indentBasis;
    transition: background-color .3s;

    &:hover {
        background-color: $greyLight5;
    }

    .manage-user__actions {
        display: flex;

        .button--action {
            margin-left: $indentBasis;
        }
    }

    .manage-user__wrapper {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .base-select {
        width: 100%;
    }

    .user-permissions {
        padding-top: $indentBasis;
    }
}