.file-preview {
    border: $classicBorder;
    padding: 17px;
    margin-bottom: 12px;
    display: flex;
    background-color: $white;
    border-radius: 4px;

    &__info {
        flex-grow: 1;
        overflow: hidden;
    }
    &__name {
        font-family: $BrandMedium;
        font-size: 14px;
        color: $blackLight;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}