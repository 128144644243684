.folder-main-container {
    padding: 0 0 30px 0;
    box-sizing: border-box;

    .agency_add_dropdown {
        float: none;
        position: absolute;
        top: 70px;
        right: 70px;

    }
}

.agency_project_actions {
    .dropdown-body .dropdown-body__content .asset-actions__item {
        width: 220px;
        padding: 10px 16px 10px 16px;
        margin-bottom: 0;
        .button--action {
            padding: 0;
            color: $greyDefault;
            height: 24px;
            .material-icons {
                color: $greyDarker3;
            }
        }
    } 
}

.folder-item__actions {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 8px;
    line-height: 1.2;
    width: 100%;
    box-sizing: border-box;
    padding: 11px 5px 11px 5px;
    border-top: 1px solid $greyDarker4;
}

.folder-item__actions-item {
    float: left;
    font-family: $BrandRegular;
    width: 25%;
    text-align: center;
    cursor: pointer;

    &:hover {
        .folder-item__actions-item__title {
            color: $defaultColor;
        }

        svg {
            path {
                fill: $defaultColor;
            }
        }
    }
}

.add-asset-modal {
    .input-pair {
        margin-bottom: 0;
    }
    &.content-store {
        .submit-btn {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            border-radius: 0;
            z-index: 2;
        }
    }
    .content-store-step-container--filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;

        & > * {
            width: 49%;
        }
    }
    .add-folder-asset-container {
        height: 100%;
        .search-results--container {
            padding: 20px 0 0 0;

            .grid-view__wrapper {
                margin: 0;
                width: auto;
            }

            .grid-view__item {
                width: 100%;
                padding: 0;
                margin-bottom: 20px;
            }
        }

        .aside-modal__body {
            padding-bottom: 34px;
        }

        .counter {
            margin-top: 10px;
        }
    }
}

.folder-item__actions-item__title {
    color: $greyDarker4;
}

.folder-item__actions-item__icon {
    display: flex;
    align-items: center;
    height: 25px;
    justify-content: center;
    &:after {
        content: '';
        display: inline-block;
    }

    svg {
        path {
            fill: $greyDarker4;
        }
    }
}

.folder-item__actions-item__clone-icon {
    svg {
        width: 16px;
        height: 18px;
    }
}

.folder-item__actions-item__copy-url-icon{
    svg {
        width: 21px;
        height: 10px;
    }
}

.asset-filtered-out {
    display: none !important;
}

.folder-section {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;

    &__title {
        span {
            padding-left: 20px;
            margin-left: 20px;
            border-left: 1px solid $blackLight;
            font-family: $BrandRegular;
            font-size: 14px;
            color: $greyDefault;
            line-height: 20px;
        }
    }
}

.full-layout--middle.content-stores {
    position: relative;
}

.content-store {
    .data-not-loaded {
        .folder-section__content {
            @extend %preload;
            background: white;
            img {
                display: none;
            }
        }
    }
}

.content-store-header {
    background: $greyLight7;
    padding-left: 2*$indentBasis;
    padding-right: 2*$indentBasis;
    position: relative;

    .dropdown {
        padding-bottom: 0;
    }

    .add-content-store--button {
        border-radius: 3px;
        background: $blue;
        color: $white;
        font-family: $BrandLight;
        font-size: 14px;
        height: 34px;
        line-height: 34px;
        padding-left: 30px;
        padding-right: 20px;
        width: 125px;
        border-color: $blue;
    }
    .dropdown-body {
        .button--action:not(.dropdown-body__content__close) {
            width: 100%;
        }
    }

    %folder-path-hover {
        color: $navy;
        svg {
            path:first-child {
                fill: $blue!important;
            }
        }
    }

    &--action-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        & > div,
        & > .simple-tooltip-wrapper {
            margin-left: calc(#{$indentBasis} * 2);
            display: block;
        }

        & > button {
            &.button--action {
                margin-left: calc(#{$indentBasis} * 2);
            }
        }

        .saved-searches-dropdown {
            margin-left: 0px;

            svg {
                width: 20px !important;
                height: 17px !important;
            }

            .dropdown-title:after {
                content: none;
            }

            .button--action[disabled] {
                background-color: $greyDarker2 !important;
            }
        }
    }

    .content-store-description {
        display: flex;
        flex-grow: 1;

        .info {
            padding-top: 5px;
            display: flex;
            flex-direction: column;
            flex: 1;
            .title {
                color: $blackLight;
                font-family: $BrandMedium;
                font-size: 22px;
                line-height: 26px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .folder-description {
                max-width: 650px;
                color: $blackLight;
                font-family: $BrandRegular;
                font-size: 12px;
                line-height: 24px;
            }

            .description-wrapper {
                align-items: flex-start;
                flex-wrap: nowrap;
            }
        }
        .backToButton {
            display: flex;
            margin-top: 6px;
            margin-right: $indentBasis;
            margin-left: 4px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--left {
        float: left;
        height: 70px;
    }

    &--top {
        display: flex;
        justify-content: space-between;
    }

    &--bottom {
        align-items: flex-end;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-top: 0px;

        .native-email-sender {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .tooltip__container {
                margin-left: 4px;
            }

            .tooltip__body {
                right: 163px;

                &:before {
                    right: 18px;
                }
            }
        }

        .contact-person-link {
            min-width: 250px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 13px;
            line-height: 19px;
            font-family: $BrandMedium;
            overflow: hidden;

            a {
                margin-left: $indentBasis;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: $BrandRegular;
            }
        }
    }
    @media #{$IE} {
        .asset-actions__item .button--action__toolTip.button--action__toolTip--bottom {
            right: -45%;
        }
        .saved-searches-dropdown .button--action__toolTip.button--action__toolTip--bottom {
            right: -76%;
        }
        .content-store--toggle {
            &.classic-view-toggle{
                .button--action__toolTip.button--action__toolTip--bottom {
                    right: -50%;
                }
            }
            .button--action__toolTip.button--action__toolTip--bottom {
                right: -27%;
            }
        }
    }
}

.content-store__thumbnail {
    &__icon {
        display: flex;
        margin: 0 13px 0 0;
        background: white;
        border-radius: 50%;
        border: 1px solid $inputBorderColor;
        width: 35px;
        height: 35px;
        padding: 5px;
        overflow: hidden;
        box-sizing: border-box;
        justify-content: center;
    }
}


.button--action__toolTip {
    z-index: 100500;
}

.gbcc-info .button--action__toolTip {

    &:after {
        left: 97%;
    }
}

.filter-button {
    z-index: 1;
    @media #{$IE} {
        .filter-settings .button--action__toolTip.button--action__toolTip--bottom {
            right: -127%;
        }
    }
}

.search-in-store {
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    align-items: center;
    padding-top: 20px;
    padding-left: 0;

    .button--action {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__close.button--action {
        margin-left: calc(#{$indentBasis} * 2);
    }
}


.grid-view {
    .content-stores {
        .agencies-search {
            justify-content: space-between;
            padding-top: 0;
            margin-bottom: 2*$indentBasis;
        
            & ~ .react-contextmenu-wrapper,
            & ~ .search-results--container {
                .search-results--grouped {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.hot-search-switcher {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0 0 30px;
    align-items: center;
    vertical-align: middle;

    &--label {
        font-size: 14px;
    }

    &--buttons {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;

        .button--action {
            background: $white;
            color: $blackLight;
            border: 1px solid $greyDarker2;
            border-radius: 10px;
            padding: 5px 10px;
            line-height: 14px;
            height: 23px;
            font-size: 13px;
            margin-right: 10px;

            &:hover {
                background: $blackLight;
                color: $white;
            }

            &:disabled {
                background: $blackLight !important;
                color: $white !important;
            }
        }
    }
}

.content-stores {
    .search-text-input--wrapper {
        width: 280px;
    }

    .search-results--container {
        .filter-tags {
            margin: 0;
        }
    }

    .content-store-header--breadcrumbs {
        .breadcrumbs {
            margin-top: -3*$indentBasis;
        }
    }

    .bulk-actions {
        .bulk-actions--dropdown {
            .dropdown-body {
                left: 0;
                right: auto;
                button {
                    text-decoration: none;
                    &:disabled {
                        color: $greyDarker2;
                        &:hover {
                            background: $white;
                            color: $greyDarker2;
                        }
                    }
                }
            }

        }
    }

}

.preview-modal.content-store {
    .search-results--container {
        height: calc(100vh - 208px);
        overflow: auto;
        padding: 0;

    }

    .content-store-step-container {
        padding: 20px;

        .input-pair {
            &__title-box {
                &__text {
                    text-transform: capitalize;
                }
            }
        }

        &--scrollable {
            height: calc(100vh - 150px);
            overflow-y: auto;
            margin-bottom: 15px;
        }
        &--filters {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .input-pair {
                flex-basis: 49%;
            }
            .input-pair__title-box {
                display: none;
            }
        }

        .button--action:not(.dropdown-title) {
            margin-left: 10px;
            margin-top: 15px;
        }

    }
    .preview-modal__body {
        width: $modalDefaultWidth;
        flex-grow: 0;
        flex-shrink: 0;
        background: $greyLight6;
    }
    .manage--buttons {
        display: flex;
        justify-content: flex-end;
    }

    .single-asset-actions-dropdown {
        .button--action {
           margin: 0;
        }
    }
    @media #{$IE} {
        .preview-modal__body {
            width: 100%;
        }
    }
}

.custom_folder--preview {
    background-color: $white;
}

.grid-view {
    .full-layout--middle.content-stores {
                .bulk-actions {
                    padding: 20px 20px 5px 40px;
                    position: relative;

                    .dropdown-body {
                        z-index: 9;
                    }
                }
                .search-results {
                    @extend %custom_folder--thumbnail;
                    @extend %custom-folder-preview;

                    &--container {
                        min-height: 100vh;
                        margin-top: -12px;

                        .panel-header {
                            margin-top: 12px;
                            margin-bottom: 4px;
                            padding-bottom: 12px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: $blackLight;
                            font-family: $BrandRegular;
                            font-size: 16px;
                            line-height: 23px;
                            height: 32px;
                            border-bottom: 1px solid $greyDarker2;

                            .dropdown.with-arrow {
                                .dropdown-title {
                                    padding-right: 32px;
                                }
                            }
                            // &:hover {
                            //     cursor: pointer;
                            // }
                            // &:before {
                            //     content: '';
                            //     display: block;
                            //     width: 0;
                            //     height: 0;
                            //     border-style: solid;
                            //     position: relative;
                            //     border-width: 7px;
                            //     top: 4px;
                            //     border-color: transparent transparent transparent $blackLight;
                            //     margin-right: 0;
                            // }
                            // &.active {
                            //     &:before {
                            //         margin-right: 10px;
                            //         top: 8px;
                            //         border-color: $blackLight transparent transparent transparent;
                            //     }
                            // }
                            // &.campaign {
                            //     &:before {
                            //         content: none;
                            //     }
                            // }
                        }

                        .search-results {
                            height: 0;
                            overflow: hidden;
                            transition: height 1.2s ease-out;
                        }
                        .opened {
                            .panel-header {
                                &:before {
                                    margin-right: 10px;
                                    top: 8px;
                                    border-color: $blackLight transparent transparent transparent;
                                }
                                &.not-collapsed{
                                    cursor: default;
                                    &:before {
                                        border: none;
                                    }
                                }
                            }
                            .search-results {
                                height: auto;
                                overflow: visible;
                                transition: height 1.2s ease-out;
                            }
                        }
                    }
                    &--grouped {
                        border: none;
                        width: 100%;
                        margin: 0 15px;

                        &:first-child {
                            .collapse-button {
                                margin-top: 0;
                            }
                        }

                        .collapse-body {
                            padding: 4px 0 0 0;
                        }

                        &.collapse--opened {
                            margin-left: 3px;
                            margin-right: 3px;

                            .collapse-body > div {
                                display: flex;
                            }
                            .collapse-button {
                                margin: 0 10px;
                                width: calc(100% - 25px);

                                &:before {
                                    margin-right: 10px;
                                    top: 8px;
                                    border-color: $blackLight transparent transparent transparent;
                                }
                            }
                        }
                        .collapse-body > div {
                            flex-wrap: wrap;
                        }
                    }
                }
                .search-text-input--container {
                    padding: 0;
                    flex-grow: 0;
                    max-width: 300px;

                    .search-text-input {
                        .base-text {
                            box-shadow: none;
                        }
                    }
                }
            }
}

.added_user--container {
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 10px;

    .added_user {
        min-width: 200px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;

        &--inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            background: $greyDefault;
            border-radius: 10px;
            line-height: 21px;
            white-space: nowrap;
            padding: 0 10px;
            margin-right: 20px;
        }

        &--name {
            color: $white;
            font-size: 13px;
            margin-right: 10px;
        }

        &--remove {
            transform: rotate(45deg);
            color: $white;
            font-size: 21px;
        }
    }

    .input-pair__input-box {
        width: 200px;
    }
}

.folder-item__action--divider {
    border-bottom: $classicBorder;
    margin: $indentBasis 0;
}

.folder-item__actions-item-copy-url {
    .copy-url-tooltip {
        position: absolute;
        color: $defaultColor;
        background: $greyLight7;
        box-shadow: $grayShadow1;
        padding: 15px;
        bottom: 10px;
        left: 0;
        z-index: 2;
        width: 100%;
        word-break: break-all;
        box-sizing: border-box;
        font-size: 10px;

        &.copy-url-tooltip--hidden {
            visibility: hidden;
        }

        &.copy-url-tooltip--ready--for--copy {
            visibility: visible;
        }
    }
}

.content-store-folder-item__actions {
    min-width: 230px;
    .asset-actions__item, .asset-actions__item--popup-mode {
        height: 35px;
        .button--action {
            font-size: 14px;
            font-family: $BrandRegular;
            height: 100%;
            text-transform: none;

            &.with-icon{
                padding-left: 15px;
                .material-icons {
                    position: static;
                    margin-right: 7px;
                }
            }
            &:hover {
                background-color: $hoverBg;
            }
        }
    }
    .export_to_xls--container .button--action {
        height: 35px;
    }
    .folder-item__actions-item-copy-url svg{
        margin-bottom: 2px;
    }
}

.campaign-field {
    display: flex;
    font-family: $BrandRegular;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 10px;
    text-transform: capitalize;
    &--name {
        color: $greyDarker3;
        flex: 1 2 30%;
    }
    &--value {
        color: $blackLight;
        flex: 1 1 70%;
        word-break: break-word;
    }
}

.preview-modal.content-store.campaign {
    .content-store-step-container {
        height: 100vh;
    }
    .right-align-buttons {
        margin: 20px;
    }
}

.campaign-asset-type_select {
    height: 60px;
}

.buttons-group {
    display: flex;

    button {
        margin-left: 10px;

    }
}

.buttons-group.edit-mode {
    button:last-child {
        margin-left:40px;
        &:before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            left: -20px;
            background: $greyDarker2;
        }
    }
}

.name-cell  { width: 20%; }
.description-cell  { width: 33%; }
.type-cell  { width: 20%; }
.owner-cell  { width: 15%; }
.date-cell  { width: 12%; }

@media screen and (max-width: $modalDefaultWidth) {
    .preview-modal.content-store .preview-modal__body {
        width: 100%;
    }
}


.data-not-loaded {
    .custom_folder--preview,
    .folder-section__content--container {
        height: 246px !important;

        * {
            display: none;
        }
    }
    .panel-header {
        display: none !important;
    }

    .playlist-item-container .actions__container {
        display: none !important;
    }

    .custom_folder--preview,
    .folder-section__content--container,
    .folder-section__title,
    .playlist-item-container,
    .playlist-item-container .description,
    .playlist-item-container .info {
        @extend %preload;
    }
}

.dropdown-body {
    &__content__items {
        .button--action.with-icon {
            .material-icons {
                color: $blackLight;
            }
        }
    }
    .actions-wrapper {
        padding: 0;

        &:hover {
            background: none;
        }
    }
}

.content-store-page {
    .search-in-store {
        padding: 0 15px;
    }

    .search-results-info {
        &-content-actions {
            &--container {
                display: flex;
            }
        }

        &--items-amount {
            text-transform: none;

            .counter {
                padding: 0;
            }

            &.button--action {
                color: $greyDefault !important;
                font-size: 13px;
            }
        }
    }
}
.disclaimer_container {
    .url-input-container {
        left: 0;
        top: 30px;
        right: auto;
    }
}

.add-agent-modal {
    .base-radio {
        &.with-infoToolTip {
            & > button {
                align-items: center;
            }
        }
    }
}

.cs-search {
    margin-top: 3*$indentBasis;
    .saved_search_details {
        justify-content: initial;
        margin-bottom: 2*$indentBasis;
        align-items: center;
        flex-wrap: wrap;
        .dropdown {
            height: auto;
        }
    }
    .saved_search_details, .search-results-controls {
        padding-right: 3*$indentBasis;
    }
    .saved_search_details--title {
        display: flex;
        align-items: center;
        h3 {
            margin-bottom: 0;
        }
        .content-store__thumbnail__icon {
            svg {
                margin: auto;
                width: 14px;
                height: 14px;
                path {
                    fill: $greyDarker;
                }
            }
        }
        .divider {
            margin: 0 $indentBasis 0 2*$indentBasis;
            border: 1px solid $greyDarker;
            height: 24px;
        }
    }
    .saved_search_details--actions {
        margin-left: auto;
        margin-top: 5px;
    }
    .back-to-folder {
        font-family: $BrandMedium;
        font-size: 22px;
        line-height: 32px;
        text-transform: initial;
        svg {
            max-width: 16px;
            max-height: 16px;
        }
    }
    .cs-saved-search-actions {
        display: flex;
        align-items: center;
        .saved-search-controls {
            margin-left: $indentBasis;
        }
    }
    .error_page-container {
        margin: 3*$indentBasis 0 2*$indentBasis;
        .no-result-container {
            flex-direction: column-reverse;
            .error_image {
                margin-left: 0;
                width: 72px;
                margin-bottom: 2*$indentBasis;
            }
        }
    }
}


@import "../components/carousel/style";
@import "../components/company_preview_page/styles";
@import "../components/content_store_field/style";
@import "../components/get_info_store/styles";
@import "../components/download_content_modal/style";
@import "../components/cvp_search_content_store/style";
@import "./customer_video_workspace";
@import "../components/tree_panel/style";

@import "./style_responsive";
