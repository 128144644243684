.DraftEditor-root {
    *[style*="font-weight: bold"] {
        font-family: $BrandBold;
    }

    *[style*="font-weight:bold"] {
        font-family: $BrandBold;
    }
}

.input-pair,
.input-pair__title-box,
.input-pair__input-box,
.input-box {
    display: inline-block;
}

.input-pair {
    .base_block_select {
        .base-input {
            .base-checkbox,
            .base-radio {
                margin-bottom: 0;
            }
        }
    }
}

.dropdown-body {
    z-index: 11;
    button:not(.selected):not(.base-checkbox__icon):not(.submit-relation):not(.search-input-actions__button):not(.anime-toggle--button):not(.dropdown-body__content__close):not(.user-profile--button) {
        &:disabled {
            color: $greyDarker2 !important;
            &:hover {
                background: inherit;
            }
        }

        &.active {
            color: $greyDefault !important;
        }
    }

    .collapse {
        .collapse-body {
            padding: 0;
        }
        &.collapse--with-arrow .collapse-toggle .collapse-toggle__icon {
            .collapse-toggle__icon__arrow {
                svg {
                    path {
                        fill: $navy !important;
                    }
                }
            }
        }
    }
}

.collapse .collapse-button:focus {
    border-radius: 0;
}

.async_input_dropdown {
    .async_input_dropdown__wrapper {
        .button--action {
            &.async_input_dropdown__button {
                width: 100%;
                height: 100%;
            }
        }
    }
}

/* Remove clear buttons in IE */
.base-text-input::-ms-clear {display: none; width:0; height:0;}

.input-pair .input-pair {
    padding: 0;
}

.dummy .input-pair {
    display: flex;
    padding: 5px 0;
    margin-bottom: 5px;
}

.modal__content .input-pair {
    display: flex;
}

.input-related-box {
    position: relative;
}

.input-pair__title-box {
    font-family: $BrandMedium;
    display: flex;
    float: left;
    align-items: stretch;
    padding-top: 6px;
    color: $blackLight;
    font-size: 14px;
    padding-right: 10px;

    .input-pair__title-box__text {
        text-transform: none;
    }
}

.input-pair__title-box,
.input-pair__input-box {
    position: relative;
    box-sizing: border-box;
}

.input-pair__input-share-box {
    position: relative;
    box-sizing: border-box;
    width:90%;
}

.input-pair--vertical {
    .input-pair__title-box,
    .input-pair__input-box {
        display: block;
        width: auto !important;
    }
    .base-radio {
        display: block;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.input-pair--disabled {
    &.input-pair--title-component {
        .input-pair__title-box {
            opacity: 1;
        }
    }

    * {
        pointer-events: none;
    }
    .required_caption_checkbox {
        .base-checkbox, a {
            pointer-events: all;
        }
    }
    .react-select-box {
        background-color: $greyLight5;
    }
}

.input-pair--disabled-input {
    .input-pair__input-box {
        pointer-events: none;
        opacity: 0.8;
    }
}

.form-field-wrapper {
    &.input-pair-options--empty {
        .input-pair {
            &.authorization-field {
                display: none;
            }
        }
    }
}

.sales-container {
    .sales-header {
        background-color: $greyDarker4;
        color: $greyDefault;
        font-size: 14px;
        padding: 5px 0 5px 10px;
        margin: 5px 0;
        @extend %tahoma-font;
    }
    .data-pair {
        padding-left: 10px;
    }
}


// .data-pair should be used for [Label]: [Text] elements
.data-pair {
    padding-bottom: 4px;

    .data-pair__title {
        @extend %tahoma-font;
        text-transform: uppercase;
        color: $black;
        margin-right: 8px;
        font-size: 10px;
        line-height: 18px;
    }

    .data-pair__value {
        display: inline;
        font-size: 12px;
        line-height: 18px;
        color: $defaultColor;

        &--block {
            display: block;
        }
    }

    &.block-view {
        .data-pair__title,
        .data-pair__value {
            display: inline-block;
        }
    }
}

.checkbox--button,
.radio--button,
.radio--circle {
    display: none;
}

.base-radio {
    display: inline-block;
    margin: 5.5px 0;
}

.base-radio--checked {
    &.disabled {
        .base-radio__icon {
            border-color: $greyDarker2;
            &:after {
                background-color: $greyDarker2;
            }
        }
    }
}
/* Text input */

.base-textarea {
    vertical-align: top;
    padding: 5px 10px;
    line-height: 1.6em;
    font-family: Arial, sans-serif;
    max-height: 300px;
}

.number-input {
    padding-right: 0 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    // Fix for mobile safari adding 3px padding
    .base-textarea {
        text-indent: -3px;
    }
}


.input-pair--nonempty,
.input-pair--focused,
.input-pair--nonempty.input-pair--focused  {
    .input-pair__title-box {
        color: $greyBlueDark;
    }
}

.base-text-input,
.datepicker__input,
.number-input,
.base-textarea {
    width: 100%;
    box-sizing: border-box;

    // Remove browser styling
    box-shadow: none;
}


.input-pair--file {
    .button--action {
        padding: 0 $indentBasis;
        display: flex;
        align-items: center;
    }

    .input-pair__input-box__error,
    .error {
        margin-top: -11px;
    }

    .mac-file__flex-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

    }

    .mac-file__titles {
        width: 100%;

        .mac-file__titles__item {
            word-break: break-all;
            display: block;
            min-height: 28px;
            padding-left: 9px;
            font-family: $BrandMedium;
            line-height: 34px;
            border-left: 1px solid $inputBorderColor;
            border-right: 1px solid $inputBorderColor;
            border-top: 1px solid $inputBorderColor;
        }
        .mac-file__titles__item:last-child {
            border-bottom: 1px solid $inputBorderColor;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            margin-bottom: 10px;
        }
        .mac-file__titles__item:first-child {
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            border-top: 1px solid $inputBorderColor;
            margin-top: 10px;
        }
    }
}

.react-select-box-options {
    input[type='checkbox'] {
        & + label {
            vertical-align: middle;
        }
    }
}

th.saved-searches-item__checkbox {
    input[type='checkbox'] {
        margin-right: -17px;
    }
}

.button--action {
    &__spaced {
       margin-left: 15px;
    }

    &.yellow {
        background: $orange;
        border-color: $orange;

        &:hover {
            background: $orange;
            border-color: $orange;
        }
    }
}

.button--action--green {
    background: $saladGreen !important;
    margin-bottom: 10px;
    color: $black;
    border-color: $greyDarker4;

    &:hover {
		background: $saladGreen !important;
		color: $black;
		text-decoration: none;
	}
}

.button-link {
    color: $blue;
    display: inline-block;

    .button-link__text {
        color: $blue;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.notifications .notification-center .toast_message button.button--action.button-link,
button.button--action.button-link {
    color: $blue !important;
    display: inline-block !important;
    margin: 0 5px !important;
    line-height: 16px !important;
    height: 22px !important;
    padding: 3px !important;
    text-transform: none !important;
}

// Fonts
// Inputs fonts
.input-pair__input-box__error,
.error,
.base-text-input,
.base-textarea,
.number-input,
.react-select-box-label,
.react-select-box-option {
    font-size: 14px;
    font-family: $BrandRegular;
}

.upload-list-controls__item,
.button--generate-xls,
.button--file,
.button--action-light,
.button--add,
.button--action:not(.dropdown-title),
.button--cancel,
.button--close {
    @extend %button--action;
}

button {
    &.upload-list-controls__item,
    &.button--generate-xls,
    &.button--add,
    &.button--file,
    &.button--action-light {
        @extend %button-white;
    }

    &.button--cancel,
    &.button--close {
        @extend %button-white;
    }
}
