.toast-wrapper {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1111111;
}

.toast {
    background: $white;
    display: flex;
    align-items: flex-start;
    padding: 16px 50px 16px 17px;
    box-shadow: $grayShadow3;
    border-radius: 8px;
    margin-bottom: 10px;
    width: fit-content;
    position: relative;
    border: 1px solid $greyDarker2;

    &.hidden-toast {
        padding: 0 !important;
        width: 0 !important;
        height: 0 !important;

        * {
            display: none !important;
        }
    }

    &.toast-active {
        background-color: $blueLight4;
    }

    &:hover {
        background: $hoverBg;
        cursor: pointer;
    }
    &_icon {
        svg { 
            width: 20px;
            height: 20px;
            margin-top: 2px;
        }
        &.SUCCESS {
            svg path {
                fill: $saladGreen;
            }
        }
        &.WARNING {
            svg path {
                fill: $orange;
            }
        }
        &.ERROR {
            svg path {
                fill: $red;
            }
        }
        &.INFO {
            svg path {
                fill: $blue;
            }
        }
    }
    &_message {
        width: 310px;
        font-family: $BrandRegular;
        font-size: 14px;
        color:$greyDefault;
        margin: 0 15px 0 17px;
        line-height: 22px;
        white-space: initial;
        word-break: break-word;
        button {
            color: $blue;
        }
        .feedback-details-link {
            display: inline-flex;
            padding-right: 0;
            padding-left: 0;
        }
        .export_to_xls--container {
            display: inline-block;
            .button--action {
                line-height: 22px;
                height: auto;
                padding: 0;
                font-family: $BrandRegular;
                color: $blue;
                &:hover {
                    background: transparent;
                }
            }
        }
    }
    @media #{$IE} {
        &_message {
            white-space: normal;
            word-wrap: break-word;
        }
    }
    .toast_login {
        margin: auto 0;
    }
    .toast_close {
        width: 20px !important;
        min-width: 20px !important;
        height: 20px !important;
        padding: 0 !important;
        position: absolute;
        top: 17px;
        right: 17px;

        svg {
            path {
                fill: $greyDarker;
            }
        }
    }
    .toast_collapse {
        .collapse {
            border: none;
            padding: 0;
            .collapse-button {
                color: $blue;
                padding-bottom: 0;
                padding-top: 0;
            }
            &.collapse--opened {
                .collapse-button {
                    display: none;
                }
            }
        }
    }
}
.toast-modal-transition-enter {
    transition: 1s;
    transform: translateY(-200%);
}

.toast-modal-transition-enter-active {
    transform: translateY(0);
}

.toast-modal-transition-leave-active {
    transition: 1s;
    transform: translateY(-200%);
}




.block-modal {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    height: 100%;

    .overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.5;
    }

    .block-modal--container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block-modal--container-inner {
        width: 70%;
        max-width: 800px;
        background: white;
        padding: 60px 100px;
        font-size: 1.6rem;
        line-height: 2rem;

        .block-modal--icon {
            margin-right: 40px;

            svg {
                width: 100px;
                height: 100px;
            }

            .material-icons {
                font-size: 10rem;
            }
        }
    }

    &.ERROR {
        .block-modal--container-inner {
            border-top: 10px $red solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $red;
            }
            svg {
                path {
                    fill: $red;
                }
            }
        }
    }

    &.INFO {
        .block-modal--container-inner {
            border-top: 10px $blue solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $blue;
            }
            svg {
                path {
                    fill: $blue;
                }
            }
        }
    }

    &.WARNING {
        .block-modal--container-inner {
            border-top: 10px $orange solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $orange;
            }
            svg {
                path {
                    fill: $orange;
                }
            }
        }
    }

    .block-modal--container-content {
        display: flex;
    }

    .block-modal--container-footer {
        display: flex;
        justify-content: flex-end;
    }
}
