.workspace_section {
    display: flex;
}
.workspace_level {
    margin: 32px 0;
}

.workspace_title {
    font-family: $BrandMedium;
    font-size: 22px;
    line-height: 32px;
}

.workspace_navigation_link {
    font-size: 16px;
    line-height: 24px;
}

.workspace-members-list {
    margin-top: $indentBasis;
}

.add-member-button {
    margin-top: 2px;
    margin-left: 2px;
}

.workspace_search {
    width: 280px;
}

.rejection_reason-text {
    font-size: 1.4rem;
}

.content_refresh {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0;
}

.workspace_validation-wrapper {
    padding: 0 !important;

    .dropdown {
        float: none;
    }

    &.hidden {
        display: none;
    }
}

.workspace_datatable_filter {
    display: flex;
    align-items: center;

    .workspace-filter-dropdown {
        .base-input {padding: 0}
    }
    .checkbox-group {
        display: flex;
        flex-direction: column;
        .base-checkbox {
            padding: 9px 16px 9px 16px;
            &:hover {
                background-color: $hoverBg;
            }
        }
        .base-checkbox__label {
            margin-left: 8px;
            font-family: $BrandRegular;
            text-transform: none;
            white-space: break-spaces;
        }

        .base-checkbox.base-checkbox--checked {
            .base-checkbox__label {
                font-family: $BrandMedium;
            }
        }
    }

    .dropdown .dropdown-body {
        width: 290px;
    }
}

.dt-cell--SAP\.TABLE\.STATES\>SAP\.FIELD\.WORKFLOW\>SAP_COM\>SAP\.FIELD\.ASSIGNEE,
.dt-cell--assignee {
    .dropdown .dropdown-body {
        width: 280px;
    }
}

div[data-tip-workspace] {width: 0; height: 0; transform: translate(15px, 15px);}

.reasons-list {
    display: flex;
    flex-wrap: wrap;

    .reason {
        background: $blueLight5;
        margin: 0 $indentBasis $indentBasis 0;
        line-height: 24px;
        font-size: 14px;
        padding: 0 $indentBasis;
    }
}

.asset-action__modal-title {
    display: flex;
    align-items: center;
    .asset-sapId {
        margin-left: 8px;
    }
}

.dropdown.withoutHover {
    .dropdown-body__content__items > div {
        &:hover {
            background-color: transparent;
        }
    }
}

.workspace-icon-centered-dropdown-wrapper {
    float: left;
    margin-left: 37px;
    height: 24px;
}
.workspace-comment-dropdown {
    .dropdown-title {
        ol, ul {
            padding: 0;
            margin: 0;
        }
    }
    .dropdown-title.with-icon {
        .material-icons {
            width: 16px;
            overflow: hidden;
        }
    }
    .dropdown-title:not(.with-icon) {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        & > span {
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: flex-start;
            justify-content: flex-start;
            text-transform: none;
            white-space: nowrap;
            display: block;
            max-height: 100%;

            * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.workspace_content_block {
    .dt-cell--thumbnailIcon {
        .asset-usage {
            display: none;
        }
    }

    .dt-cell {
        &.dt-cell--actions {
            min-width: 20px;
            flex-basis: 0;
            padding: 0;
        }
        &.dt-cell--doi {
            max-width: 130px;
        }
        &.dt-cell--name {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    .dt__item {
        .dt-cell--status--inProgress,
        .dt-cell--status--approved,
        .dt-cell--status--rejected {
            padding-left: 24px;
            position: relative;
            &::before {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                left: 13px;
                top: 18px;
            }
        }

        .dt-cell--status--inProgress {
            &::before {
                background-color: $orange;
            }
        }

        .dt-cell--status--approved {
            &::before {
                background-color: $saladGreen;
            }
        }

        .dt-cell--status--rejected {
            &::before {
                background-color: $red;
            }
        }
    }

    .dt-cell--usage {
        .usage--container {
            span {
                text-transform: capitalize;
            }
        }
    }

    .dt-cell--name {
        span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .dt-cell--rejection-reason,
    .dt-cell--approval-reason {
        &.greyColor {
            .button--action {
                svg {
                    path {
                        fill: $greyDarker;
                    }
                }
            }
        }
    }

    .dropdown {
        .dropdown-body__content__items {
            & > span {
                padding: 0;
            }
        }
    }
}

.workspace-review-modal {
    .asset-preview__content {
        padding-bottom: 64px;
    }
}

.sap_owner_datatable {
    .dt-cell--thumbnailIcon {
        min-width: initial;
        max-width: 54px;
    }
}

.workspace_datatable_loader {
    margin-top: -62px;
}

@media screen and (max-width: 380px) {
    .workspace_content_block {    
        .dt-cell {
            &.dt-cell--checkbox {
                padding-right: 0;
                width: 18px;
                min-width: 18px;
            }
            &.dt-cell--doi,
            &.dt-cell--status {
                padding-right: 0;
            }
            &.dt-cell--actions {
                .asset_actions_dropdown {
                    margin-right: 0;
                }
            }
            &.dt-cell--status {
                padding-right: 0;
            }
        }
    }
}

.workspace_aside_modal,
.workspace_section {
    .assets-list__item__details-link {
        color: $blue;
    }
}

.workspace_aside_modal_body {
    margin: 2px 2px 0;
}

.add-member-modal {
    .base-modal--body {
        overflow-y: visible !important;
    }
}

.workspace-review-actions {
    display: flex;
    padding: 2*$indentBasis 3*$indentBasis;
    gap: $indentBasis;
    border-top: $classicBorder;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #fff;
}
