.replace-asset-modal {
    .upload-wrapper__metadata {
        border-left: $classicBorder;
        height: calc(100vh - 65px);

        .replace_video--message {
            display: flex;
            border: 1px solid $blue;
            background: $blueLight5;
            padding: 12px 15px;
            margin: 24px 24px 0 24px;
            border-radius: 4px;
            line-height: 18px;

            .icon {
                margin-right: 12px;

                svg {
                    width: 16px;
                    height: 16px;
                    path {
                        fill: $blue;
                    }
                }
            }
        }

        .edit_asset_container--header {
            z-index: 0;
            padding-top: 12px;
            padding-bottom: 12px;

            h2 {
                font-size: 1.4rem !important;
            }
        }

        .tabs__menu {
            display: none;
        }

        .manage-asset-form {
            padding: 24px 24px 0 24px;
            margin-top: -35px;
            height: calc(100% - 131px);

            .tabs__panel {
                padding-top: 20px;
                height: 100%;
                position: relative;

                .compliance_info {
                    position: absolute;
                    bottom: 56px;
                }

                .compliance_requirements {
                    display: none;
                }

                .form-list {
                    max-height: calc(100vh - 400px);
                    overflow-x: auto;
                }
            }
        }
    }

    .upload-wrapper__dnd {
        padding: 54px 24px 0 24px;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: visible !important;

        .no-dropzone {
            .upload-content--holder {
                display: none;
            }
        }
    }

    &.aside-modal {
        .aside-modal__header {
            position: relative;
            z-index: 2;
            min-height: 60px;
            padding-top: 6px;
            padding-bottom: 12px;
        }

        .aside-modal__body {
            height: calc(100vh - 65px) !important;
            padding: 0;
            margin-top: -60px;

            .replace-asset-content-modal {
                height: calc(100% - 15px);

                .file-preview {
                    margin-top: 24px;
                    position: relative;

                    .file-preview__name {
                        margin-bottom: $indentBasis;
                        padding-right: $indentBasis * 3;
                    }

                    .file-preview__button {
                        margin-top: -2px;
                        position: absolute;
                        top: 13px;
                        right: 13px;
                    }
                }
            }
        }
    }

    &.aside-modal.aside-modal--entire {
        .aside-modal__body {
            height: 100vh !important;

            .replace--footer {
                padding: 24px;
                background: $white;
                border-top: $classicBorder;
                border-left: $classicBorder;
                margin-left: -1px;
            }
        }

        .file-preview {
            width: calc(100% - 10px);
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .upload-content {
        padding-bottom: 0;
    }
    .content-file-replacer {
        .file-preview {
            border: none;
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;

            &__button {
                margin-top: -3px;
            }
        }
    }
    .replace-asset-content-modal__error {
        font-size: 14px;
        color: $red;
    }
    .edit_asset_container--buttons {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0;
        button {
            width: 100%;
        }
    }
    .replace-asset-content--link {
        margin-right: 25px;
    }
    .upload-wrapper__dnd--content {
        padding: 0;
        min-height: initial;
        //max-height: calc(100vh - 150px);
        border-right: 0;
        height: 100%;
    }
    .replace-asset-content-modal .compliance_indo_wrapper {
        bottom: 12px;
    }
}

@import "./style_responsive";
