.scroll-to-top {
    &.button--action:not(.dropdown-title) {
        width: 32px;
        height: 32px;
        position: fixed;
        bottom: $indentBasis * 2;
        right: $indentBasis * 2;
        opacity: 0;
        visibility: hidden;
        box-shadow: $grayShadow3;

        &:hover {
            border-color: $blue !important;
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
}