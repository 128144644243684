@media #{$mobile} {
    .react-select-box--opened {
        position: static !important;
        z-index: auto !important;

        .react-select-box__search {
            position: fixed;
            top: 0;
            left: 0;
            width: calc(100% - 70px);
            background: $white;
            height: 30px;
            padding: 5px;
            z-index: 100000001;

            input.select-search__input.base-text-input {
                width: calc(100% - 10px) !important;
                border: $classicBorder !important;

                &:focus {
                    border: $classicBorder !important;
                }
            }
        }

        .react-select-box-options--opened {
            width: 100% !important;
            height: 100vh !important;
            top: 0 !important;
            left: 0 !important;
            max-height: none !important;
            visibility: visible;
            padding-top: 40px;
            z-index: 100000000 !important;

            .close_select_options {
                position: absolute;
                top: 5px;
                right: 5px;
                min-width: 0 !important;
                margin: 0;
            }
        }
    }

    .select__clear-all-container {
        display: none;
    }
}

@media #{$iPad} {
    .select__clear-all-container {
        display: none !important;
    }
}



