.dgl-test-label {
    display: inline-block;
    height: 20px;
    font-family: $BrandRegular;
    background: $red;
    color: $white;
    line-height: 23px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: 1.5px;
    padding: 0 5px;
    margin-left: 7px;
}


// header accessibility fix
@media #{$mobile} {
    .dgl-test-label {
        display: none;
    }
}

@media #{$mobileSmall} {
    .region--top {
        .aside-header-box--right {
            padding-left: 0;
        }
        .page--width-wrapper .logo {
            padding-left: 0;
        }
    }
}
