.admin-dashboard {
    .dashboard-actions {
        margin-bottom: $indentBasis;
        display: flex;
        align-items: center;

        .button--action {
            margin-right: $indentBasis;

            svg {
                max-width: 1.3rem;
                max-height: 1.3rem;
            }
        }
    }

    .section-header {
        padding: 2*$indentBasis;
        font-family: $BrandMedium;
        font-size: 16px;
        line-height: 24px;
        border-bottom: $classicBorder;
    }

    .section-wrapper {
        padding: $indentBasis 0 $indentBasis*2;
        display: flex;
        align-items: flex-start;
    }

    .widget {
        width: 50%;
        padding: $indentBasis $indentBasis*2;
        border-radius: 12px;
        border: $classicBorder;
        background: $white;
        box-sizing: border-box;

        &:first-child {
            margin-right: $indentBasis*3;
        }

        &-title {
            padding: $indentBasis 0;
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-items {
            padding: 2*$indentBasis 2*$indentBasis 2*$indentBasis 3*$indentBasis;
        }

        &-item {
            &:last-child {
                .widget-item-title,
                .collapse-button {
                    border-bottom: none;
                }
            }
            .collapse-button {
                cursor: default;
                padding: $indentBasis 0;

                .widget-item-title {
                    padding: 0 2*$indentBasis 0 0;
                    border-bottom: none;
                }
            }

            .collapse-body {
                padding: 0;
            }

            .collapse-toggle {
                align-items: center;

                &__title {
                    flex: 1;
                }

                &__icon {
                    cursor: pointer;
                }
            }

            &-title {
                padding: $indentBasis 2*$indentBasis;
                border-bottom: $classicBorder;
                line-height: 24px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-wrapper {
                display: flex;
                align-items: center;
            }

            &-title-text {
                margin-right: 6px;
                font-family: $BrandMedium;
                text-transform: capitalize;
            }

            &-status {
                padding-left: 14px;
                font-family: $BrandRegular;
                position: relative;

                &:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    background-color: $saladGreen;
                }

                &.failed {
                    &:before {
                        background-color: $red;
                    }
                }
            }
        }

        &-subitem {
            padding: $indentBasis 0;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .linkText {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            svg {
                width: 16px;
                margin-left: 4px;
                vertical-align: middle;

                path {
                    fill: $blue;
                }
            }
        }
    }

    .users {
        padding-top: 2*$indentBasis;

        .assets-region__nothing-found {
            padding: 0;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
            color: $greyDarker3;
        }

        .dt__sort-controls {
            padding: 0 23px;
        }

        .dt__item {
            height: 41px;
            max-height: 41px;
        }

        .dt-cell {
            padding: 4px 13px;
        }

        .dt-cell--actions {
            flex-basis: 0;
            min-width: 20px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .dt-cell--id {
            flex-basis: 80px;
            min-width: 80px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .dt__items {
            padding: 0 2*$indentBasis;
            & > div {
                &:first-child {
                    .dt__item {
                        border-top: none;
                    }
                }
            }
        }
    }

    .dashboard-nav {
        padding: 4px 0;

        &-item {
            min-height: 88px;
            padding: calc(#{$indentBasis} * 2);
            border-radius: 12px;
            border: $lightBorder;
            background: $white;
            box-shadow: $grayShadow1;
            box-sizing: border-box;
            position: relative;
            text-decoration: none;
            display: block;
            transition: border-color, box-shadow .3s;

            &:hover {
                text-decoration: none;
                border-color: $blue;
                box-shadow: $grayShadow2;
            }
    
            &__title {
                margin-bottom: $indentBasis;
                font-family: $BrandMedium;
                font-size: 16px;
                line-height: 24px;
                color: $blue;
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }

                svg {
                    width: 16px;
                    margin-left: 4px;
                    path {
                        fill: $blue;
                    }
                }
            }
        }

        &-counter {
            height: 24px;
            padding-left: 14px;
            color: $greyDefault;
            font-size: 12px;
            line-height: 24px;
            position: relative;

            span {
                font-family: $BrandMedium;
            }

            &:before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                display: block;
                position: absolute;
                left: 0;
                top: 9px;
                background-color: $saladGreen;
            }

            &.pending {
                &:before {
                    background-color: $orange;
                }
            }

            &.loading {
                @extend %preload;

                &:before {
                    display: none;
                }
            }
        }
    }
}

.resource-link {
    margin-bottom: 2*$indentBasis;

    &-description {
        margin-top: $indentBasis;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .copy-link__title {
        margin-bottom: $indentBasis;
    }

    .copy-link__form {
        align-items: center;
    }

    .button--action {
        margin-left: $indentBasis;
    }
}


.permissions-field--list {
    &--item {
        padding: $indentBasis * 2;
        position: relative;
        padding-right: 50px;
        display: flex;
        justify-content: flex-start;
        font-size: 1.4rem;

        &:hover {
            background: $hoverBg;
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &-target {
            width: 100px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
}

@media #{$mobile} {
    .admin-dashboard {
        .section-wrapper {
            flex-wrap: wrap;
        }

        .widget {
            width: 100%;
            &:first-child {
                margin-bottom: 2*$indentBasis;
                margin-right: 0;
            }
        }
    }
}
