.banner {
    margin-top: 20px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    border-radius: 12px;
    padding: 24px 32px;

    .banner--info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
    }

    .banner--icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: 64px;
        height: 64px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .banner--text {
        margin-left: calc(#{$indentBasis} * 2);

        .banner--name {
            font-family: $BrandBold;
            font-size: 18px;
            margin-bottom: $indentBasis;
            color: $blackLight;
        }

        .banner--description {
            font-size: 14px;
            line-height: 24px;
            color: $defaultTextColor;
        }
    }

    .banner--link {
        a {
            color: $blue;
            font-size: 14px;
            text-decoration: none;
            padding: $indentBasis/2 $indentBasis;
            line-height: 24px;
            border: 1px solid $blue;
            white-space: nowrap;
            border-radius: 6px;
            font-family: $BrandMedium;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 20px;
                height: 20px;
                padding: 0 $indentBasis / 2 0 0;

                path {
                    fill: $blue;
                }
            }

            &:hover {
                text-decoration: none;
                background: $blueLight5;
            }

            &:focus {
                outline-offset: 2px;
                border-color: $blue;
            }
        }
    }
}

.dropdown.as-pinned-icon {
    position: fixed;
    bottom: 64px;
    right: 10px;

    .banner {
        border:  none;
        margin-top: 0;

        &:hover {
            background: none;
        }

        .banner--info {
            display: block;
        }
        .banner--link,
        .banner--text {
            margin-left: 0;
        }

        .banner--description {
            white-space: normal;
            margin-bottom: calc(#{$indentBasis} * 2);
        }

        .banner--link {
            display: inline-block;
            margin-bottom: $indentBasis;
        }
    }

    .dropdown-body {
        margin: 0;
        padding: calc(#{$indentBasis} * 2);
    }

    .dropdown-title {
        background: $blue;
        height: 40px;
        width: 40px;
        border-radius: 20px;

        &:hover {
            background: $greyBlueDark;
        }

        .material-icons {
            color: $white;
        }
    }

    .dropdown-body--top {
        right: calc(40px + #{$indentBasis});
        margin-bottom: -40px;
    }
}