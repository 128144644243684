%pseudo-header {
    &:before {
        font-size: 16px;
        font-family: $BrandBold;
        color: $blackLight;
        display: block;
        padding: 10px 0 15px 0;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
}

.tabs.wizard {
    .carousel-panels {
        height: 100%;

        .carousel-panels--inner {
            height: 100%;
        }
        .carousel-panel {
            width: 0 !important;
            height: 0 !important;
            margin-left: 0 !important;

            &.active {
                margin-left: 0 !important;
                box-sizing: border-box;
                height: 100% !important;
                width: 100% !important;
            }
            .carousel-scroll {
                .carousel-scroll--inner {
                    padding: 3*$indentBasis;
                    box-sizing: border-box;
                }
            }
        }
    }
}

.reuse--modal .tabs.manage-asset-form.sale {
    .carousel-panel {
        width: auto !important;
    }
}

.manage-asset-form {
    &.isValidationEngine {
        .input-pair__input-box__error,
        .input-pair__input-box .error {
            display: none;
        }

        .error-new-engine {
            display: flex;
            width: 100%;
        }
    }

    &.sale {
        .form-field-wrapper {
            & > .with-pseudo-title {
                @extend %pseudo-header;

                &.title,
                &.asset_is {
                    &:before{
                        content: 'Asset Definition - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
                    }
                }

                &.personal_data {
                    &:before{
                        content: 'Governance - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
                    }
                }

                &.sales_role {
                    &:before{
                        content: 'Metadata - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
                    }
                }
            }
        }
        .published_on_container {
            .form-field-wrapper .input-pair {
                width: auto;
                margin-bottom: 0;
            }
        }
    }

    .add-modal__fieldset {
        .input-pair {
            &__input-box {
                .base-textarea,
                .base-tags-input,
                .react-tagsinput-input input,
                .react-tagsinput-tag {
                    font-family: $BrandRegular;
                    font-size: 14px;
                }
                .react-tagsinput-input {
                    max-width: 100%;
                    input {
                        max-width: 100% !important;
                    }
                }
            }
        }

        .base-checkbox {
            &__label {
                text-transform: none;
            }
        }

        .for-mobile-app {
            .input-pair {
                margin-bottom: 0px;
            }
            .base-checkbox {
                &__label{
                    color: black;
                }
            }
            margin-bottom: 16px;
        }

        .publishing-platforms-fields {
            .input-pair {
                margin-bottom: 0;
            }

            .for-mobile-checkbox-area {
                display: flex;
                justify-content: flex-start;

                .base-checkbox {
                    margin: 6px 0;

                    &__label {
                        color: black;
                    }
                }
            }
        }
        .sales-fields {
            .input-pair__title-box__text{
                text-transform: none;
            }
        }

        .add-modal__brand-private {
            width: auto;
            margin: 0;

            .form-field-wrapper {
                margin: 10px 0;
                display: flex;
            }
        }
    }

    .base-tags-input {
        min-height: 60px;
    }

    .input-pair__input-box .error {
        background: $white;
    }

    .button--action:not(.dropdown-title) {
        &.content-owner-btn {
            margin-top: 11px;
            margin-bottom: 5px;
            margin-left: 1px;
            text-transform: none;
        }
    }
}

.button--action__toolTip {
    text-transform: none;
}
.published_on_container, .asset_is_container, .restriction_type_container {
    .Select-clear-zone {
        display: none !important;
    }
}

.published_on_container {
    .form-field-wrapper {
        position: relative;
    }
}

.asset_is {
    .button--action__toolTip {
        width: 240px;
        white-space: normal;
        z-index: 1000;
        text-align: left;
        background: $white;
        color: $greyBlueDark;

        &:after {
            top: -5px !important;
            bottom: auto !important;
            margin-left: -105px;
            background: $white;
        }
    }
}

.content-owner-wraper {
    margin-top: 20px;
    .async_input {
        &.input-pair {
            margin-bottom: 0;
        }
    }
    .button--action:not(.dropdown-title) {
        margin-left: 2px;
        text-transform: none;
        color: $greyDefault;
    }
}

.async_input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

.authorization-field {
    .simple-toolTip {
        width: 150px;
        white-space: normal;
        top: auto !important;
        bottom: 45px;
        text-align: left;
        margin-left: 35px;
        background: $white !important;
        color: $greyBlueDark !important;
    }
}
.published_on_container,
.authorization-field {
    .simple-toolTip {
        font-size: 13px;
        background: $white;
        color: $greyBlueDark;

        &:after {
            background: $white;
        }
    }
}

.prefiled-from-master-asset,
.prefiled-smart-metadata {
    .input-pair__input-box {
        position: relative;

        .base-tags-input,
        .base_block_select,
        .bound-datepicker,
        .base-text-input,
        .base-textarea,
        .react-select-box {
            border: 1px solid $blue !important;
        }

        &:before {
            color: $blue;
            content: 'Smart metadata';
            background: $white;
            position: absolute;
            top: -16px;
            line-height: 16px;
            white-space: nowrap;
            right: 0;
            font-size: 13px;
            font-family: $BrandRegular;
        }

        .base_block_select {
            padding-top: 5px;
            padding-left: 5px;
            margin-left: -5px;
        }
    }
}

.prefiled-smart-metadata {
    .input-pair__input-box {
        &:before {
            content: 'Smart metadata';
        }
    }
}

.prefiled-from-master-asset {
    .input-pair__input-box {
        &:before {
            content: 'Added from master';
        }
    }
}

.info-tooltip {
    .companySize--list {
        padding-left: 25px;

        li {
            margin-bottom: 5px;
        }
    }
}

.asset-field--toolTip {
    position: relative;

    .info-tooltip {
        left: 8px;
        position: relative;
        .material-icons {
            font-size: 16px;
            color: $greyDarker3;
        }
        .tooltip__open-btn {
            position: absolute;
            top: -4px;
            padding: 0;
        }
    }

    &.asset-owner {
        .info-tooltip {
            .tooltip__open-btn {
                margin-left: 3px;
            }
        }
    }
}

.edit_metadata_header {
    display: flex;
    justify-content: space-between;

    .compliance_requirements {
        margin-bottom: 5px;
    }

    .coresap-loader {
        .svg-loader {
            font-family: $BrandRegular;
            font-size: 1.4rem;

            svg {
                fill: $greyDarker;

                circle {
                    stroke: $greyDarker;
                }
            }


        }
    }

    .auto_fill_metadata {
        flex-shrink: 0;
        margin-left: calc(#{$indentBasis} * 2);
    }
}

