.config-service-page {
    .dt-cell--actions__container {
        display: flex;

        & > span {
            margin-right: calc(#{$indentBasis} * 2);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .loggers-actions {
        display: flex;

        .button--action {
            margin-left: 2*$indentBasis;
        }
    }

    .dt-cell--level,
    .dt-cell--default,
    .dt-cell--actions {
        flex-basis: 100px;
        max-width: 100px;
        min-width: 100px;
        box-sizing: border-box;
    } 
}