.compliance_info {
    display: flex;
    position: relative;
    &-title {
        font-family: $BrandRegular;
        font-size: 12px;
        line-height: 18px;
    }
    .error-new-engine {
        position: absolute;
        left: 0;
    }
    .base-checkbox {
        margin-right: $indentBasis;
        .base-checkbox__icon {
            margin-right: 0;
        }
    }
    .compliance_info-title {
        a {
            &:focus {
                outline-offset: 2px;
            }
        }
    }
}

.compliance_info .compliance_info-title, .compliance_requirements {
    .button--action {
        display: inline-block;
        color: $blue;
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        text-transform: none;
        font-family: $BrandRegular;
        font-size: 12px;
        line-height: 18px;
        background-color: transparent;
        margin-left: 4px;
        height: 24px;
        &:hover {
            background-color: transparent;
        }
    }
}

.compliance_requirements {
    .button--action {
        padding: 0 !important;
        margin: 0;
        svg {
            width: 12px;
            vertical-align: middle;
            path {
                fill: $blue;
            }
        }
    }
}

.replace-asset-content-modal .compliance_indo_wrapper {
    position: absolute;
    bottom: 56px;
}

.project-workspace-add-file-modal .edit_asset_container--buttons {
    .compliance_info {
        position: absolute;
        bottom: 34px;
        background-color: $white;
        padding: 8px 0 8px 10px;
        .button--action {
            width: initial;
        }
        .base-checkbox__icon {
            width: 18px;
        }
    }
}

.confirm-modal.update_publishing_modal {
    .modal__header {
        display: none;
    }
    .modal__content {
        text-align: left;
        p {
            font-size: 14px;
            line-height: 21px;
        }
        padding-bottom: 24px;
    }
    .compliance_info {
        align-items: flex-start;
    }
    .base-checkbox__icon {
        margin-top: 2px;
    }
    .modal__footer {
        padding: 0;
    }
    .modal__footer__buttons {
        .button--action {
            width: 50%;
            border-radius: 0;
            margin: 0;
        }
    }
    .modal, .modal__footer {
        border: none;
    }
}