$defModalWidth: 1000px;


.aside-modal {
    &.manage-metadata-modal, &.manage-presets-modal {
        width: 100%;
        max-width: $defModalWidth;
    }
}
.manage-metadata-modal,
.manage-presets-modal {
    @include asideModalAnimation($defModalWidth);
    width: 100%;
    max-width: $defModalWidth;
    height: 100%;
    background: $white;
    box-shadow: $grayShadow4;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;

    .modal__footer {
        height: 40px;
        padding: 0;
        border: none;

        .button--action {
            width: 100%;
            border-radius: 0;
        }
    }

    .aside-modal--header {
        background: $greyLight7;
        padding: 12px 20px;
        border-bottom: $classicBorder;

        h2 {
            font-size: 18px;
            color: $blackLight;
            font-family: $BrandMedium;
        }
    }
    .aside-modal__container {
        .aside-modal__body {
            padding-right: 0;
        }
    }
}

.manage-metadata--container {
    display: flex;

    .asset-preview__heading {
        text-indent: -9999px;
        padding: 5px !important;
        display: none;
    }

    .tabs-list-scroll {
        margin-left: 1px;
    }

    .asset-preview__section {
        margin: 27px $indentBasis/2 2*$indentBasis;
        padding-top: 21px;
        border-top: $classicBorder;

        &:first-child,
        &:last-child {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }
    }

    .tabs-list {
        .profile-name {
            padding: 0;
            margin-right: -21px;
            position: relative;

            .base-input {
                margin-bottom: 15px;

                .base-text-input {
                    height: 42px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border: $classicBorder !important;
                    border-right: 0 !important;
                    padding-right: 65px;
                }
            }

            .button--action {
                position: absolute;
                top: 9px;
                right: 27px;
            }
        }

    }

    .form-container {
        &--scroll {
            .tabs.manage-asset-form {
                margin-top: 0;
                width: calc(100% + 20px);
                height: 100%;
            }
        }

        .smart-metadata-switcher {
            padding: 10px 20px;
            display: flex;
            justify-content: flex-start;

            &--content {
                h2 {
                    font-size: 16px;
                    color: $blackLight;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            .base-input {
                margin-right: 15px;
            }
        }


        .preview-modal__header__nav-tabs {
            display: none;
        }

        .asset-preview {
            height: 100%;
            overflow: visible;
            padding: 0 20px 0 0;
            width: auto;


            .asset-preview__heading {
                margin-left: 0;
                margin-right: 0;
                padding: 10px 20px;
                border: $classicBorder;
            }

            .asset-preview__section:last-child {
                min-height: 0;
            }

            .input-pair {

                &__title-box {
                    float: none;
                }

                &__input-box {
                    width: 100%;
                }
            }
        }
    }

    .manage-metadata--items-existing {

        .profile-items--list {
            padding: 0;
            margin-top: 3*$indentBasis;
        }

        .profile-item {
            border-left: 4px solid $orange;
            background-color: $white;

            &.profile-item--shared {
                border-left-color: $blue !important;
            }

            &.profile-item--selected {
                border-left: 4px solid $orange;
            }

            .dropdown {
                min-width: 0;
                height: auto;
                padding-bottom: 0;
            }

            &:not(.tab-item--selected):hover {
                background: $hoverBg;
            }
        }
    }

    .manage-metadata--addNewWrapper {
        border-top: $classicBorder;
        padding-top: 2*$indentBasis;
        margin-top: 3*$indentBasis;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .manage-metadata-notice {
        padding: 10px 20px;
        margin-top: 15px;
        border: 1px solid $greyDarker2;
        background-color: $greyLight6;
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.5;

        .notice {
            font-family: $BrandMedium;
        }
    }
}

.add_new_profile {
    .base-input {
        position: relative;
        margin-bottom: 18px;
    }

    .left-align-buttons {
        .button--action {
            margin-right: $indentBasis;
        }
    }

    &--button {
        margin-bottom: 10px;
        color: $blue;
        text-transform: none;
    }
}


.metadata-profile-options {
    .base-select {
        width: 200px;

        .Select-control .Select-placeholder,
        .Select-control .Select-value {
            padding-left: 40px;

            .Select-value-label .custom-value {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .Select-input {
            margin-left: 30px;
            max-width: 100px;
        }
    }
}
.react-select__menu {
    .button--action.manage_profiles_button {
        box-shadow: none;
        border-radius: 0;
        color: $blackLight;
        height: auto;
        justify-content: flex-start;
        width: auto;
        min-width: 100%;

        &:first-child {
            border-bottom: 1px solid $inputBorderColor;

            path {
                fill: $blackLight;
            }
        }

        svg {
            margin-right: 10px;
        }
    }
}

.new-metadata-modal {
    .base-input {
        position: relative;
    }
}

.metadata-modal__footer {
    background-color: $greyLight7;
}

.metadata-profile-actions.dropdown {
    .dropdown-body {
        .button--action {
            span {
                margin-left: 0;
            }

            &.gold_icon {
                path {
                    fill: $orange !important;
                }
            }
        }
    }
    svg {
        path {
            fill: $blackLight;
        }
    }
}

@import './style_responsive';
