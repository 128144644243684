.project-workspace-page__header {
    border-bottom: 1px solid $greyDarker2;
    padding: $indentBasis 2*$indentBasis 2*$indentBasis;
    &__top {
        display: flex;
    }

    .project-workspace__actions {
        display: flex;
        margin-left: auto;
        .publishing-form-link {
            margin-right: 2*$indentBasis;
        }
        .bulk-actions {
            &--dropdown {
                .dropdown-body {
                    left: auto;
                }
            }
        }
    }

    .project-details__id {
        &__link {
            text-decoration: none;
        }
    }

    &__details {
        display: flex;
        align-content: flex-end;
        color: $blackLight;
        margin-top: 10px;
        position: relative;
        font-size: 14px;

        .project-details__name {
            flex-shrink: 1;
            flex-grow: 3;
            padding-right: 50px;
        }

        .project-details__coordinator {
            flex-shrink: 1;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            color: $greyDarker3;
            &__name {
                color: $blackLight;
            }
        }
    }

    &--loading {
        .project-details__id,
        .project-details__name,
        .project-details__coordinator {
            background: $greyDarker;
            @extend %preload;
        }
    }
}

@import "./project_workspace_header_responsive";
