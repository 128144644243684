.admin-system-actions {
    .collapse-body {
        padding: 0;
    }

    .collapse-toggle {
        align-items: center;
    }

    .grid-view {
        padding: 12px 0 4px;
    }

    .action {
        &-item {
            min-height: 56px;
            padding: calc(#{$indentBasis} * 2);
            border-radius: 12px;
            border: $lightBorder;
            background: $white;
            box-shadow: $grayShadow1;
            box-sizing: border-box;
            position: relative;
            display: block;
            transition: border-color, box-shadow .3s;

            &-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-wrapper {
                display: flex;
                align-items: center;
            }

            &-icon {
                margin-right: $indentBasis;

                svg {
                    width: 17px;
                    height: 17px;

                    path {
                        fill: $greyDarker3;
                    }
                }
            }

            &-title {
                height: 24px;
                font-family: $BrandMedium;
                font-size: 16px;
                line-height: 24px;
            }

            .button--action {
                min-width: 60px;
                height: 32px;

                svg {
                    path {
                        fill: $greyDarker3
                    }
                }
            }
        }

        &-item--link {
            width: 100%;

            &:hover {
                text-decoration: none;
                border-color: $blue;
                box-shadow: $grayShadow2;

                .action-item-title {
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }
            }

            &:before {
                content: "";
                width: 3px;
                height: 24px;
                position: absolute;
                left: 0;
                top: 2*$indentBasis;
                border-radius: 0 4px 4px 0;
                display: block;
            }

            &.red {
                &:before {
                    background-color: $red;
                }
            }

            &.yellow {
                &:before {
                    background-color: $orange;
                }
            }

            &.green {
                &:before {
                    background-color: $saladGreen;
                }
            }

            .action-item-title {
                color: $blue;
                display: flex;
                align-items: center;
    
                svg {
                    width: 14px;
                    max-width: 14px;
                    max-height: 14px;
                    margin-left: 4px;
                    path {
                        fill: $blue;
                    }
                }
            }
        }
    }
}

.sys-action-modal {
    position: relative;

    &.aside-modal {
        .aside-modal__body {
            height: calc(100vh - 64px);
            padding-bottom: 0;
        }
    }

    .input-pair__title-box {
        text-transform: none;
    }

    .button--file {
        margin-bottom: 2*$indentBasis;
    }

    .description {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: $indentBasis;
    }

    .dropdown {
        margin-bottom: $indentBasis;
        float: none;

        .dropdown-body {
            right: auto;
            left: 0;
        }
    }

    .sys-action-modal-bottom {
        margin: 0 -24px;
        position: relative;
    }

    .sys-action-modal-result {
        padding: 2*$indentBasis 3*$indentBasis;
        border-top: $lightBorder;
    }

    .sys-action-modal-button {
        padding: 2*$indentBasis 3*$indentBasis;
        border-top: $lightBorder;

        .button--action {
            min-width: 60px;
        }
    }

    .result-block {
        padding: $indentBasis 12px;
        border-radius: 8px;
        border: 1px solid;
        font-size: 12px;
        line-height: 18px;

        &-SUCCESS {
            border-color: $saladGreenDark;
            background-color: #F5FAE5;

            .result-value-icon {
                path {
                    fill: $saladGreenDark;
                }
            }
        }

        &-WARNING,
        &-WARN {
            border-color: #E76500;
            background-color: $yellow;

            .result-value-icon {
                path {
                    fill: $orange;
                }
            }
        }

        &-ERROR {
            border-color: $red;
            background-color: $redLight;

            .result-value-icon {
                path {
                    fill: $red;
                }
            }
        }

        .collapse-button {
            padding: $indentBasis 0 0;
            border-bottom: none;
        }

        .collapse-toggle__title {
            font-family: $BrandRegular !important;
        }

        .collapse-body {
            padding: 0;
        }

        .collapse--opened {
            .result-value-chevron {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .result-value {
            display: flex;

            svg {
                width: 13px;
                height: 13px;
                margin-right: $indentBasis;
            }

            &-icon {
                padding-top: 2px;
            }
    
            &-chevron {
                padding-top: 2px;
                line-height: 13px;
                svg {
                    margin-left: $indentBasis;
                    transition: transform .3s;
                }
            }
    
            &-list {
                padding-left: 3*$indentBasis;
                padding-top: $indentBasis;
            }
        }
    }

    .system-action-form {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.export_metadata_modal {
    padding-bottom: 3*$indentBasis;
    .metadata-values--container-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &-value {
            margin-top: 4px;
        }
    }
    .metadata-values--container {
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
    }
    .modal__content {
        min-height: 250px;
    }
}

.create_toast-modal {
    .closePreview {
        width: fit-content;
    }
    @media #{$IE} {
        .closePreview {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
    .toast {
        margin-left: auto;
    }
    .warningMessage {
        margin-top: auto;
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 17px;
        span {
            color: $red;
        }
    }
    .user-change-requests-manage-modal--header {
        padding: 20px 20px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-change-requests-manage-modal--title {
            font-size: 18px;
            font-family: $BrandMedium;
        }
        .button--action .material-icons {
            font-size: 22px;
        }
    }
    .toast_example_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .manage_user_form--controls {
        .button--action{
            width: 100%;
            border-radius: 0;
        }
    }
    .url-input-container {
        left: 0;
        top: 30px;
    }
}