@media screen and (max-width: 836px) {
    .admin_button_wrapper {
        bottom: $indentBasis;
        position: fixed;
    }
    .page--login {
        flex-direction: column;
    }

    .ids-wrap {
        width: 100% !important;
    }
    .ids-overlay-container {
        width: 100% !important;
        padding-top: 0 !important;
    }
    .page--login__description {
        z-index: 103;
        background: $white;
        width: 100%;
        flex-grow: 0;

        .description-wrapper {
            padding: $indentBasis * 2 $indentBasis * 2 $indentBasis * 3 $indentBasis * 2;
            margin: 0;
            width: 100%;
            box-sizing: border-box;
        }

        .description__title {
            font-size: 30px;
            line-height: 32px;
            margin: $indentBasis 0;
        }

        .description__subtitle {
            text-align: center;
            padding-left: 62px;
        }

        .sap-logo {
            margin: $indentBasis * 2;
        }
    }
    .page--login__form__login {
        width: 100%;
        max-width: 100%;
    }
    .page--login__form {
        width: 100%;
        min-width: 100%;
        flex-grow: 1;
        height: 100%;

        &__internal {
            width: auto;
            left: 0;
        }
        .login-form-section {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .aside-modal-transition-enter {
        max-width: 0;
        overflow: hidden;
    }

    .aside-modal-transition-enter-active {
        max-width: 100%;
        transition: max-width 0.3s;
    }

    .aside-modal-transition-leave {
        max-width: 100%;
    }

    .aside-modal-transition-leave-active {
        max-width: 0;
        transition: max-width 0.3s;
        overflow: hidden;
    }
}

@media screen and (min-width: 836px) and (max-width: 1024px) {
    .ids-overlay-container {
        margin-top: 0 !important;
    }
}

@media only screen and (min-width: 544px) {
    body {
        .ids-overlay-container.ids-overlay-container--theme_fiori {
            max-width: 515px;
        }
    }
}

@media screen and (max-width: 520px) {
    .page--login__description .description__title {
        font-size: 20px;
        line-height: 22px;
    }

    .page--login__description__text .description-wrapper {
        font-size: 14px;
        line-height: 16px;
    }

    .page--login__description .description__subtitle {
        padding-left: 62px;
        font-size: 16px;
    }

    .login-form-section__header {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 4px;
    }

    .admin_button_wrapper {
        bottom: 0;
    }
}