// Remove scroll from body when modal is open (base-modal.jsx)

html.modal-open, html.crop-html {
    overflow-y: hidden;
    min-height: 100%;
    #app {
        position: relative;
        z-index: 1;
    }
}

html.replace-open {
    #app {
        position: relative;
        z-index: auto;
    }
}

.attention-word {
    font-family: $BrandMedium;
    color: $red;
}

.modal-bold-word {
    font-family: $BrandMedium !important;
}

.modal-root-node {
    z-index: 1010;
    //can be used for all browsers
    @media #{$IE} {
        position: absolute;
    }
}

.modal-embed-node {
    z-index: 1;
}

.copy-link__form__wrapper {
    padding: 10px 0 5px;
    display: flex;
}

@media (max-width: 1080px) {
    // Disable backdrop dragging on ipad
    body.modal-open {
        position: fixed;
        width: 100%;
    }
}

.modal__footer__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal {
    position: relative;
    margin: 0 auto; //top margin is set by JavaScript in 'base_modal.jsx'
    transition: all 0.2s;
    width: 726px;
    height: auto;
    background: $white;
    border-radius: 3px;
    border: 1px solid $blueLight5;
    max-width: 100vw;

    .button--action {
        cursor: pointer;
        flex-shrink: 0;
    }

    .input-pair__input-box input[type=file] {
        cursor: pointer;
    }

    .clear_all--action {
        margin-right: 10px;
        color: $blue;
    }
    .input-pair__title-box {
        color: $blackLight;
    }
}

.modal__close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;

    svg {
        width: 16px;
        height: 16px;

        path {
            fill: $blackLight;
        }
    }

    &:hover {
        svg {
            path {
                fill: $greyBlueDark;
            }
        }
    }
}

.close-button-wrapper {
    position: relative;
    z-index: 1;
}

.feedback-modal.base-modal--container {
     .base-modal {
        width: initial;
        height: initial;
    }
    .feedback-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px;
    }
}

.modal__content {
    padding: 25px 20px 30px 20px;
    font-size: 14px;
}

.modal__footer {
    text-align: right;
    border-top: 1px solid $blueLight5;
}

// One field modal

.one-field-modal {
    @extend %form-modal--small;
    .input-pair__input-box {
        width: 100%;
    }
}

//Two fields modal
.two-field-modal {
    @extend %form-modal--medium;
}
.two-field-modal__buttons {
    margin-top: 20px;
    text-align: right;
    width: 80%;
}

.two-field-modal.external {
    .input-pair__title-box {
        width: 30%;
    }
    .input-pair__input-box {
        width: 70%;
    }
}

.two-field-modal.feedback-modal {
    .modal__header__text {
        font: normal 16px/30px $BrandRegular;
    }
    .modal__header {
        padding: 10px;
    }
    .modal__close {
        top: 18px;
        right: 18px;
    }
}

.reset-pass-modal {
    .input-pair__title-box {
        width: 25% !important;
    }

    .input-pair__input-box {
        width: 55% !important;
    }

    .reset-pass-modal__error {
        margin-bottom: 5px;
        padding: 5px 8px;
        display: block;
        background-color: $red;
        color: $white;
        font-size: 11px;
    }
}


.replace-open.modal-open {
    #progress-component {
        z-index: 9999;
    }
}


// Notification modal
.notification-modal {
    &__message,
    &__message__text {
        b {
            font-family: $BrandMedium;
        }

        .asset-id {
            color: $blue;
        }
    }

    .modal {
        width: 602px;
        min-height: 60px;
        height: auto;
        max-width: 100vw;
        padding: 0 20px;
        font-family: $BrandRegular;
        font-size: 16px;
        line-height: 24px;
        color: $blackLight;
        background: $modalBg;
        border-radius: 0;
        box-shadow: $grayShadow4;
        box-sizing: border-box;

        .modal__header {
            padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} / 2);
            border: none;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            background-color: #fff;

            .modal__close {
                width: 24px;
                height: 24px;
                position: absolute;
                top: calc(#{$indentBasis} * 2);
                right: 0;
                cursor: pointer;
            }
        }

        .modal__header__text {
            min-height: 24px;
            padding-right: calc(#{$indentBasis} * 6);
            font-family: $BrandMedium;
            text-decoration: none !important;
            text-transform: capitalize;
        }

        .modal__content {
            padding: 0 calc(#{$indentBasis} / 2);
        }

        .modal__content--text {
            padding: calc(#{$indentBasis} * 3) 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
        }

        .modal__icon {
            width: 96px;
            margin-right: calc(#{$indentBasis} * 3);

            svg {
                width: 96px;
                height: 96px;
            }
        }
    }

    .modal__footer {
        padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} / 2);
        overflow: hidden;
        border: none;

        .modal__footer__buttons {
            display: flex;
            justify-content: flex-end;

            button {
                margin-left: $indentBasis;
                min-width: 60px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

// Animations
.modal-backdrop {
    transition: opacity ease-in 0.3s;

    &.modal-backdrop-transition-enter {
        opacity: 0;
    }
    &.modal-backdrop-transition-enter-active {
        opacity: 0.6;
    }
    &.modal-backdrop-transition-leave {
        opacity: 0.6;
    }
    &.modal-backdrop-transition-leave-active {
        opacity: 0;
    }
}

.modal-backdrop,
.ajax-progress-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: $blackLight;
    opacity: 0.6;
    height: 100%;
}

/* LOADING_SCREEN */
.opacity-transition-enter {
    opacity: 0.01;
}
.opacity-transition-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
}
.opacity-transition-leave {
    opacity: 1;
}
.opacity-transition-leave-active {
    opacity: 0.01;
    transition: opacity 0.3s;
}

/* end of Hack region */




.modal-content-transition-enter .modal{
    top: 0;
    margin-top: -200px;
    margin-left: 0;
    opacity: 0;
}

.modal-content-transition-enter-active .modal{
    transition: margin-top 0.2s, opacity 0.1s;
    margin-top: 0;
    opacity: 1;
}
.modal-content-transition-leave .modal{
    margin-top: 0;
    opacity: 1;
}
.modal-content-transition-leave-active .modal{
    transition: margin-top 0.2s, opacity 0.1s;
    margin-top: -200px;
    opacity: 0;
}

.modal-positioner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
}

// End animations

.main-modal-style {
    .modal__header {
        background: $greyLight7;
        padding: 30px 40px 10px 17px;
        border: 1px solid $greyDarker4;

        .modal__close {
            @extend %button--close-modal;
        }
    }

    .modal__header__text {
        font-family: $BrandRegular;
        font-size: 20px;
        color: $blackLight;
    }

}

.asset-detail-container {
    .replace-video-thumbnail {
        float: left;
        position: relative;
    }
}


.replace-video-thumbnail {
    position: relative;
    .thumbnail-arrow-icon {
        position: absolute;
        right: 5px;
        top: 7px;

        svg {
            width: 10px;
            height: 10px;
        }
    }
    .thumbnail-replace-popup.opened {
        display: flex;
        flex-direction: column;
    }
}

.thumbnail-replace-popup {
    display: none;
    position: absolute;
    left: 0;
    top: 20px;
    width: 165px;
    padding: 0;
    background-color: $white;
    box-shadow: $grayShadow3;
    z-index: 1;

    .button--action {
        border-radius: 0 !important;
        justify-content: flex-start !important;
        width: 100% !important;
        margin: 0 !important;
    }

    &.opened {
        display: block;
    }
}

.thumbnail-replace-popup.side {
    left: -165px;
    top: 0;
    right: auto;
}

.cropped-thumbnail-modal {
    .base-modal {
        .image-container {
            height: 350px;
            width: 100%;
            border: 2px solid $blue;
            box-shadow: $whiteShadow;
            overflow: hidden;
            img {
                width: 100%;
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                -webkit-transform: translate(0, -50%);
            }
        }
    }
}

.base-redux-modal {
    .base-modal {
        .base-modal--body {
            max-height: calc(100vh - 210px);
            overflow-y: auto;
            box-sizing: border-box;
        }
        .base-modal--header-inner {
            text-transform: none;
        }
    }
    .modal-backdrop {
        opacity: 0.75;
    }
    .modal-positioner {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .modal {
            width: 540px;

            .input-pair {
                width: 100%;
                display: block;

                &__title-box {
                    width: 100%;
                }

                &__input-box {
                    width: 100%;
                }
            }
        }
    }

    .modal__header {
        padding-top: 10px;
        padding-bottom: 10px;
        border: 0;
        border-bottom: $classicBorder;

        &__text {
            font-family: $BrandMedium;
            font-size: 18px;
            color: $blackLight;
            line-height: 20px;
        }

        .button--action.modal__close {
            position: absolute;
            top: 5px;
            right: 5px;
            margin-right: 0;
        }
    }

    .modal__footer {
        background: $greyLight7;

        &__buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .copy_anon_url--modal-content {
        display: flex;
        justify-content: space-between;

        .copy_anon_url--link {
            border: $classicBorder;
            padding: 7px;
            flex-grow: 1;
            flex-shrink: 1;
            margin-right: 10px;
            font-size: 14px;
            word-break: break-all;
        }

        .button--action {
            margin-right: 0;
        }

        &:last-child {
            .copy-link {
                margin-bottom: 5px;
            }
        }
    }

    .input-pair__title-box {
        float: none;
    }
}

.new-metadata-modal {
    .modal__footer {
        top: 0;
        margin: 0;
        padding: 12px 20px 11px;

        button {
            margin-left: 10px;
        }
    }
}

@media only screen and (max-device-width: 415px) {
    .modal-open {
        body {
            overflow: hidden;
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .two-field-modal.feedback-modal{
        .modal {
            width: 100vw;
            position: absolute;
            height: 100vh;
            bottom: 0;
            right: 0;
            margin: 0 !important;
            .input-pair__input-box,
            .input-pair__title-box {
                width: 100%;
            }
            .input-pair {
                display: block;
            }
        }
        .modal__header {
            padding: 7px 10px;
            .modal__header__text {
                font-size: 16px;
            }
        }
        .modal__close {
            top: 14px;
        }
    }
}

.dummy.isScreeWithMobileView {
    .modal {
        .input-pair {
            flex-direction: column;


            &__input-box,
            &__title-box {
                width: 100%;
            }
        }
    }
}

.extend-expiration-modal {
    &.base-redux-modal .base-modal {
        .base-modal--body {
            overflow-y: unset;
        }
    }
}

.base-modal {
    box-sizing: border-box;
    .base-modal--form {
        .input-pair__input-box {
            min-height: auto;
        }
        div:first-child {
            .input-pair,
            .input-pair__title-box {
                padding-top: 0;
            }
        }

        div:last-child {
            .input-pair {
                padding-bottom: 0;
            }
        }
    }

    .base-modal--footer {
        .button--action {
            min-width: 60px;
            margin-left: $indentBasis;
        }
    }
}

.share-search-modal {
    .base-modal--body {
        overflow-y: visible !important;
    }
}

@import "./base_modal_responsive";
