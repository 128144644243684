.load-in-progress {
    text-align: center;
    color: $greyDarker4;
    padding: 60px 0;
    font-size: 24px;
    line-height: 30px;
}

.loader-preview-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: $pageBgColor;
}

.preload-datatable {
    .dt__sort-controls,
    .dt__item,
    .dt-grid__item {
        border: 1px solid $white!important;
        @extend %preload;
    }
    .base-checkbox {
        visibility: hidden!important;
    }
}

.preload-team.team-list {
    .team-item {
        height: 104px;
        border: 1px solid $white!important;
        @extend %preload;

        &:after {
            border-right: none;
            display: block;
        }
    }
}

.preload-items {
    .widget-item {
        height: 53px;
        margin-bottom: 4px;
        @extend %preload;
    }
}

.preload-folders-page {
    .preload-panel-header {
        min-height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: $white;
        @extend %preload;
    }

    .preload-panel-search {
        min-height: 90px;
        margin-bottom: 10px;
        background-color: $white;
        @extend %preload;
    }
}

.preload-folders {
    &--empty {
        * {
            visibility: hidden;
        }
        @extend %preload;
    }
    .panel-header {
        height: 60px;
        margin-bottom: 2*$indentBasis;
        border-bottom: none !important;
        @extend %preload;
    }
    .custom_folder--preview {
        .image,
        .description > div,
        .info > div {
            border: none!important;
            @extend %preload;
        }

        .description > div {
            height: 25px;
            margin-top: 5px;
        }

        .preload-folder-image {
            min-height: 200px;
        }
    }
}

.preload-projects {
    &--empty {
        * {
            visibility: hidden;
        }
        @extend %preload;
    }
    @extend %custom_folder--thumbnail;
    @extend %custom-folder-preview;

    .custom_folder--preview {
        * {
            @extend %preload;
            color: transparent;
        }
    }
}

.preload-sso {
    height: calc(100vh - 6*#{$indentBasis});
    max-height: 576px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .dot {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background-color: $blue;
        border-radius: 50%;
        animation: dotAnimation 1500ms cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
    }
    
    .dot:nth-child(1) {
        animation-delay: 0ms;
    }
    
    .dot:nth-child(2) {
        animation-delay: 300ms;
    }
    
    .dot:nth-child(3) {
        animation-delay: 600ms;
    }
    
    @keyframes dotAnimation {
        0%, 60%, 100% {
            transform: scale(1);
        }
        30% {
            transform: scale(1.5);
        }
    }
}

.preload-toasts {
    &__block {
        @extend %preload;
        height: 75px;
        margin-bottom: 2px;
    }
}

.preload-generated-thumb,
.preview-thumb-icon {
    width: 100%;
    height: 240px;
    border: $classicBorder;
    border-radius: 4px;
    background-color: $greyLight6;
    display: flex;
    justify-content: center;
    align-items: center;

    .files-for-upload__item__image {
        width: 80px;
        max-width: 80px;
        height: auto;
    }

    .coresap-loader {
        width: 30px;
        margin: 0 auto;
    }

    .svg-loader {
        text-indent: -9999px;
    }

    p {
        margin-top: 10px;
        font-size: 12px;
        line-height: 18px;
        color: $greyDarker3;
        text-align: center;
    }

    circle {
        stroke: $greyDarker3;
    }
}

.preload-cvp-asset-preview {
    width: 60%;
    margin-right: 40px;
    .asset-detail__preview {
        min-height: 400px;
    }
    .general-description {
        min-width: 140px;

        .description.content-type {
            border-right: none;
        }
    }
    .video-actions {
        min-width: 95px;
    }
    .metadata-title {
        background-color: $greyLight2;
    }
    .metadata-item__title {
        min-height: 20px;
        min-width: 20%;
        margin-right: 50px
    }
    .metadata-item__data {
        min-height: 20px;
        min-width:60%
    }
    .video-actions,
    .general-description,
    .cvp-asset-preview__title,
    .metadata-title {
        min-height: 30px;
    }
    .cvp-asset-preview__description {
        min-height: 70px;
    }
    .video-actions,
    .general-description,
    .cvp-asset-preview__title,
    .cvp-asset-preview__description,
    .metadata-title,
    .metadata-item__title,
    .metadata-item__data,
    .asset-detail__preview {
        @extend %preload;
    }
}

.cvp-review-asset-wrapper {
    .video-block,
    .approval-form-block,
    .input-block {
        @extend %preload;
        margin-bottom: 16px;
    }
    .video-block {
        height: 250px;
    }
    .approval-form-block {
        height: 90px;
    }
    .input-block {
        height: 70px;
    }
}

.preload-tree {
    margin-top: 8px;
    .material-icons {
        float: left;
        margin-top: 4px;
        margin-right: 8px;
        color: $greyDarker;
    }
    .preload-tree__block {
        @extend %preload;
        height: 24px;
        margin-bottom: 8px;
        background: $greyLight2;
    }
}


