.cvp-page {
    .search-in-store {
        flex-wrap: wrap;
    }

    .letter-item-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &--anchor {
            margin-right: 10px;
        }

        &--dotted-line {
            border-top: 2px dashed $inputBorderColor;
            width: 100%;
        }
    }

    .alphabetical-navigation {
        &--body {
            margin-top: -7px;
        }

        .navigation-item {
            padding: 7px 15px;
        }
    }

    .search-sortby-field--container {
        margin: 0;
        padding: 15px 15px 0;
        flex-wrap: wrap;
        box-sizing: border-box;
    }

    .items-container {
        padding-right: 30px;
        box-sizing: border-box;
    }

    .companie-item {
        background-color: $white;
        box-shadow: $grayShadow1;
        box-sizing: border-box;
        padding: 10px 15px;

        &--name {
            border-radius: 3px;
            display: block;
            color: $navy;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 5px;
            white-space: nowrap;
            margin-left: -5px;

            &:hover {
                text-decoration: none;
                background-color: $hoverBg;
            }
        }

        & .bpNumber {
            margin: 15px 0;

            &--title {
                color: $greyDarker3;
                margin-right: 10px;
            }
        }
    }
    .search-sortby-field--wrapper {
        .dropdown {
            padding-bottom: 0;
            margin-top: 2px;
        }
    }
    .error_page-container {
        margin-top: calc(#{$indentBasis} * 3);
    }
}

.search-result-companies {

    &--wrapper {
        display: flex;
        font-family: $BrandRegular;
        font-size: 14px;
        justify-content: space-between;
        margin-top: 20px;
        margin-right: -15px;
        padding: 0 15px 20px 15px;
        position: relative;
        width: 100%;
        box-sizing: border-box;

        @extend %custom_folder--thumbnail;
        @extend %custom-folder-preview;

        &.search-mode {
            flex-direction: column;

            & > .search-result-counter {
                margin-bottom: 15px;
            }

            .search-result__amount {
                font-family: $BrandMedium;
                margin-right: 5px;
            }
        }

        & .navigation-item {
            list-style: none;
            // margin-bottom: 1px;
        }

        & .navigation-item-letter {
            color: $navy;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        & .info {
            display: flex;
            color: $blackLight;
            font-family: $BrandMedium;
            font-size: 14px;
        }

        & .info__data {
            display: flex;

            & svg {
                margin-right: 8px;

                & path {
                    fill: rgba(34,34,34,0.5);
                }
            }

            &--assets {
                & svg {
                    height: 15px;
                    width: 12px;
                }
            }
        }
    }

    &--content {
        width: 100%;
        margin-right: 5px;
    }

}

@import "./style_responsive";
