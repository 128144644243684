.features_flags_page {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .system-actions__body--block {
        padding: 24px 24px 0;
        background-color: $white;
        border: $classicBorder;
        border-radius: 3px;
        display: block;
        &.unsafe {
            .block-title {
                color: $red;
                svg {
                    path {
                        fill: $red;
                    }
                }
            }
        }
        .block-title {
            margin-bottom: 16px;
            font-family: $BrandMedium;
            font-size: 18px;
            position: relative;
            padding-left: 25px;
            color: $saladGreenDark;
            svg {
                position: absolute;
                top: -3px;
                left: 0;
                width: 18px;
                height: 18px;
                path {
                    fill: $saladGreenDark
                }
            }
        }
        .block-description {
            line-height: 16px;
        }
    }
    .feature-flag-wrapper:last-child {
        .feature-flags-block {
            border: none;
        }
    }
    .feature-flags-block {
        border-bottom: $classicBorder;
        padding: 2*$indentBasis 0;

        .feature-flag-description {
            font-size: 14px;
            line-height: 24px;
        }

        .input-pair {
            padding: 0 0 $indentBasis;
            margin-bottom: 0;
        }

        .expiration-date {
            margin-top: $indentBasis;
            text-align: right;
            .bold {
                font-family: $BrandMedium;
            }
        }
    }
    .feature-flags-area {
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .feature-flags-item {
        margin: 0 auto 0 0;
        font-family: $BrandMedium;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        &--temp {
            background: rgba(240, 171, 0, 0.2);
            border-radius: 28px;
            font-family: $BrandMedium;
            padding: 2px 8px;
            font-size: 12px;
            line-height: 14px;
            color: $blackLight;
            position: absolute;
            top: -10px;
            margin-left: 10px;
        }
    }
    .remove-feature-flag {
        margin-right: 15px;
    }
}
.delete-feature-flag-confirm {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    margin: 10px 0;
    span {
        color: red;
    }
}

.input-pair.flag-enablement {
    padding-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .input-pair__input-box {
        margin-right: 10px;
    }

    .input-pair__title-box {
        font-family: $BrandRegular;
    }
}

.feature-flag-script {
    &.copy-link__form__url {
        margin-top: 16px;
        width: auto;
        max-height: none;
        white-space: normal;
    }
}

.feature-flag-script-submit {
    margin-top: 2*$indentBasis;
}
