.image-cropper-modal {
    z-index: 1999;
    // fix for onboarding tooltips
    position: relative;
    .base-modal--overlay {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 0;
    }
    .base-modal {
        padding: 0;
        .base-modal--header {
            padding: 0;
            .base-modal--header-inner {
                padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3);
            }
        }
        .base-modal--body {
            padding: 0 0 24px;
        }
        .close-modal-button {
            right: 24px;
        }
    }
    .base-modal--footer {
        padding: 0;
    }
    .crop-image-modal-header {
        display: flex;
        align-items: flex-start;
        h4 {
            font-size: 16px;
            line-height: 24px;
        }
        #thumbnail-cropper-onboarding-1 {
            display: inline;
        }
        .crop-image-modal-title {
            h4 {
                display: inline;
            }
        }
        .crop-image-modal-descr {
            margin-top: calc(#{$indentBasis} * 2);
            font-family: $BrandRegular;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .show-thumb-onboarding {
        position: absolute;
        right: 62px;
        top: 16px;
    }
    .image-crop-wrapper {
        padding: 0 24px;
        border-bottom: $classicBorder;
        .controls {
            .material-icons {
                font-size: 2.2rem;
            }
        }
    }
    .crop-image-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 0 24px;
    }
    .thumb-error {
        margin-top: 16px;
        padding: 0 24px;
    }
}

.upload-suportive-content {
    &.dropdown {
        float: none;
        &.opened {
            .dropdown-title {
                background-color: $blueLight5;
                border-color: $blue;
                color: $blue;
            }
        }
    }
    .dropdown-body {
        width: 280px;
        margin-bottom: 0;
        right: auto;
        left: 0;

        .input-pair {
            padding: 0;
        }

        .dropdown-option {
            width: 100%;
            height: 40px;
            padding: 0 calc(8px* 2);
            background: none;
            border: 0;
            border-radius: 0;
            font-family: $BrandRegular;
            line-height: 1.4rem;
            justify-content: flex-start;
            text-transform: none;
            box-shadow: none;
            color: $greyDefault;
            cursor: pointer;

            svg {
                width: 16px;
                max-width: 16px;
                max-height: auto;
                margin-right: $indentBasis;
                path {
                    fill: $greyDefault;
                }
            }

            &:hover {
                background: $greyLight5;
            }

            &.disabled {
                color: $greyDarker2 !important;
                path {
                    fill: $greyDarker;
                }
            }
        }
    }
}

.single-file-preview {
    padding: $indentBasis 11px $indentBasis 2*$indentBasis;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $greyDarker4;
    box-sizing: border-box;
    &--name {
        max-width: 240px;
        margin-right: 2px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .button--action {
        svg {
            max-width: 9px !important;
        }
    }
}

.update-thumbnail-wrapper {
    .preview-thumb, .preload-generated-thumb, .preview-thumb-icon {
        width: 100%;
    }
    .button--action.update-thumb-btn {
        margin-bottom: 5px;
    }
    .error {
        margin-top: 16px;
    }
}
