.notifications {
    position: relative;
    .dropdown-body {
        width: 460px;
        max-width: 75vw;
        padding: 0;
        top: 41px;
        z-index: 112;

        .notification-center_body--scroll {
            border-radius: 0 0 $dropdownBorderRadius $dropdownBorderRadius;
        }

        .notifications-dropdown {
            padding: 0;
            color: $greyDefault;

            &__header {
                padding: 6px 14px;
                font-family: $BrandMedium;
                font-size: 13px;
                line-height: 24px;
                background: $greyLight2;
            }

            .notifications__item {
                width: 100%;
                padding: 14px;
                display: flex;
                align-items: center;
                box-sizing: border-box;

                &--icon {
                    width: 32px;
                    height: 32px;
                    margin-right: 14px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    border: 1px solid $orange;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: $orange;
                    }
                }

                &--text {
                    font-family: $BrandRegular;
                    font-size: 14px;
                    line-height: 24px;
                    white-space: normal;
                }
            }
        }
    }
    .notifications-dropdown--wrapper {
        >button span {
            background-color: $blue;
            border-radius: 50%;
            position: absolute;
            color: $white;
            font-family: $BrandMedium;
            font-size: 9px;
            line-height: 14px;
            width: 14px;
            height: 14px;
            z-index: 1;
            text-align: center;
            border: 1px solid $white;
            top: -2px;
            right: -8px;
        }
    }
    .notification-center{
        padding: 0;
        &:hover {
            background: transparent;
        }
        &_settings .button--action.dropdown-title,
        .toast-actions .button--action {
            width: 24px !important;
            height: 24px !important;
            border-radius: 4px !important;
            &:focus {
                outline-offset: 2px !important;
            }
            .material-icons {
                margin-right: 0;
                color: $greyDarker !important;
            }
        }
        &_settings {
            .button--action.dropdown-title {
                padding: 0 4px !important;
            }
        }
        .toast-actions {
            visibility: hidden;
            opacity: 0;
            transition: all .2s;

            .button--action {
                padding: 0 2px !important;
                .material-icons {
                    font-size: 20px !important;
                }
            }
        }
        &_counter {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            background: $blue;
            border-radius: 50%;
            font-size: $BrandMedium;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: $white;
        }
        &_wrapper {
            height: 100%;
            display: flex;
            align-items: center;
        }
        &_top {
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 11px 10px 16px;
            box-sizing: border-box;
            border-bottom: $classicBorder;
            @media #{$IE} {
                .dropdown-body {
                    width: auto !important;
                }
            }
            .dropdown-body {
                width: 200px;
                padding: 7px 0;
                position: absolute;
                left: initial;
                top: 34px;
                .button--action {
                    width: 100%;
                    height: 40px;
                    padding: 10px 16px;
                }
            }
        }
        &_header {
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 24px;
            color: $blackLight;
        }
        .base-select {
            width: 240px;
            margin-right: 10px;

            .Select-clear-zone {
                display: none;
            }

            .Select-value {
                text-align: right;
            }

            .Select-control {
                border: none;
                box-shadow: none;

                .Select-arrow {
                    top: 0;
                    margin-bottom: 3px;
                    border-width: 5px 5px 0px 5px;
                }
            }

            .Select-menu-outer {
                max-height: 216px;
                padding: 8px 0;
                border: none;
                box-shadow: $grayShadow3;
                border-radius: 4px;
                box-sizing: border-box;
            }

            .Select-menu {
                max-height: 216px;
            }

            .Select-option {
                height: 40px;
                padding: 10px 16px;
                font-size: 14px;
                line-height: 24px;
                color: $greyDefault;
                box-sizing: border-box;
            }
        }
        .has-value.is-clearable.Select--single > .Select-control .Select-value {
            padding-right: 30px;
        }
        .notification-center_empty {
            padding: 32px 15px;
            text-align: center;

            svg {
                margin: 0 auto;
                width: 30px;
                height: 30px;
                path {
                    fill: $greyDarker;
                }
            }

            .material-icons {
                margin: 0 auto;
                font-size: 30px;
                color: $greyDarker2;
                display: block;
            }
        }
        .notification-center_emptyMessage {
            margin-top: 2px;
            font-size: 14px;
            line-height: 22px;
            color: $greyDarker3;
        }
        .notification-center_settings {
            margin-top: 14px;
            .dropdown-title {
                svg {
                    margin-right: 0;
                }
            }
        }
        .dropdown-title {
            margin-left: auto;
            justify-content: center !important;
        }
        .toast {
            box-shadow: none;
            width: auto;
            margin-bottom: 0;
            border: 0;

            &:hover {
                .toast-actions {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &_time {
                font-size: 12px;
                color: $greyDarker3;
            }
            &_leftSection {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &_status {
                width: 5px;
                height: 5px;
                background-color: $inputBorderColor;
                border-radius: 50%;
                margin-top: 12px;
                &.read {
                    background-color: $red;
                }
            }
        }
        .collapse-button {
            color: $blue !important;
        }
        .toast_message {
            max-width: 100%;
            width: 100%;
            button {
                color: $blue !important;
                display: inline-block !important;
                line-height: 22px !important;
                width: auto !important;
                min-width: auto !important;
                height: 22px !important;
                padding: 0 !important;
                span {
                    white-space: normal;
                }
            }
        }
        .toast-modal-transition-enter {
            transform: translateY(0);
        }

        .toast-modal-transition-enter-active {
            transform: translateY(0);
        }

        .toast-modal-transition-leave-active {
            transform: translateY(0);
        }
    }
    .sort_notification.dropdown {
        margin-top: 9px;
        margin-right: $indentBasis;

        .dropdown-title {
            height: 32px !important;
            width: auto;
            font-family: $BrandMedium !important;
        }
        .dropdown-title:after  {
            position: initial;
            margin-left: 10px;
        }
    }
    @media #{$mobile} {
        .dropdown-body{
            max-width: none;
            width: 100%;
            left: 0;
            position: fixed;
            top: 50px;
            .notification-center_body--scroll {
                height: calc(100vh - 115px) !important;
            }
        }
    }
}
